import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { IinitialState } from './index.types';
import { SECTIONS_FLAG } from './index.types';

export  const initialState:IinitialState = {
  sectionData: [],
  sectionsChanged:false,
};

export const sectionSlice  = createSlice({
  name: 'section',
  initialState,
  reducers: {
    setSections: (state, action) => {
      state.sectionData = action.payload;
    },
    updateSectionFlag: (state, action) => {
      state.sectionData =  state.sectionData.map(
        (section) => section.sectionId === action.payload.sectionId ? { ...section, flag: action.payload.flag }
          : section);
    },
    updateSection: (state, action) => {
      state.sectionData = state.sectionData.map(
        (section) => section.sectionId === action.payload.sectionId ? { ...section, ...action.payload.section, flag:action.payload.flag }
          : section);
    },
    createSection: (state, action) => {
      state.sectionData = [...state.sectionData || [], { ...action.payload, flag:SECTIONS_FLAG.CREATE }];
    },
    setSectionsChanged: (state, action) => {
      state.sectionsChanged = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
  setSections,
  updateSectionFlag,
  updateSection,
  createSection,
  setSectionsChanged } = sectionSlice.actions;

export default sectionSlice.reducer;

export const getSectionById = (id: number) => createSelector(
  (state: RootState) => state.section.sectionData,(sectionData) =>{
    if (sectionData) {
      return sectionData.find(section => {
        return section.sectionId === id;
      });
    } else {
      return null;
    } 
  });
