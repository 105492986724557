import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CounterState {
  currentLocation: string
}

const initialState: CounterState = {
  currentLocation: '',
};

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setCurrentLocation: (state, action: PayloadAction<string>) => {
      state.currentLocation = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentLocation } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
