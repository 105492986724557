import './styles.css';

import React from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { RootState } from '../../../../../store/store';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useAxios from 'axios-hooks';
import { updateDefaultAccount } from '../../../../../api/profileApi/profile';
import { GetUserDetails } from '../../../../../api/loginApi/user';
import {
  setUserName,
  setUserEmail,
  setAccount,
  setAccountList,
  setRoles,
} from '../../../../../store/UserSlice';
import { setSearchTickets } from '../../../../../store/ticketSlice';

const SideNavTop: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const managerId = useAppSelector((state: RootState) => state.auth.managerId);
  const account = user?.account;
  const accountList = user.accountList;
  const companyLogo = account?.companyLogo;

  const [, refetch] = useAxios(GetUserDetails(managerId), { manual: true });
  const [, updateAccount] = useAxios(
    updateDefaultAccount(managerId, { toAccountId: null, fromAccountId: null }),
    { manual: true },
  );

  const changeAccount = (event: SelectChangeEvent<number>) => {
    if (account?.accountId) {
      updateAccount(
        updateDefaultAccount(managerId, {
          toAccountId: Number(event.target.value),
          fromAccountId: account?.accountId,
        }),
      ).then(() => {
        refetch()
          .then((res) => {
            dispatch(setUserName(res.data.name));
            dispatch(setUserEmail(res.data.tixUser.email));
            dispatch(setAccount(res.data.account));
            dispatch(setAccountList(res.data.accountList));
            dispatch(setRoles(res.data.roles));
            navigate('/tickets');
            dispatch(setSearchTickets(true));
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  };
  return (
    <div className="box">
      {companyLogo?.filePath && (
        <img className="logo" src={companyLogo?.filePath} alt="Logo" />
      )}

      {accountList && account?.accountId && accountList.length !== 1 ? (
        <Select
          style={{
            background: 'white',
            width: '100%',
            height: '30px',
            marginTop: '10px',
            textOverflow: 'ellipsis',
          }}
          labelId="change-account"
          id="changeAccount"
          value={account?.accountId}
          onChange={changeAccount}
          data-testid={'sideNavTop-changeAccount'}
        >
          {accountList.map((account) => (
            <MenuItem
              key={account.accountId}
              value={account.accountId}
              data-testid={`sideNavTop-account-${account?.accountName}`}
            >
              {account.accountName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <h2 className="profile line-clamp-text">
          <Link
            to="/profile"
            data-testid={`sideNavMenu-${account?.accountName}-profileLink`}
          >
            {account?.accountName || 'Company'}
          </Link>
        </h2>
      )}
    </div>
  );
};

export default SideNavTop;
