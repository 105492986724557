import { createTheme } from '@mui/material';

const baseTheme = createTheme({
  typography: {
    fontFamily: 'inherit',
    body1: {
      fontSize: 14,
    },
    button: {
      textTransform: 'none',
    },
    h3: {
      fontSize: 16,
    },
    h4: {
      fontSize: 21.25,
      fontWeight: 400,
    },
    h5: {
      fontSize: 15,
      fontWeight: 400,
      paddingBottom: 8,
      textTransform: 'uppercase',
      color: '#707070',
    },
  },
  palette: {
    primary: {
      main: '#007AB3',
    },
    secondary: {
      main: '#A71930',
    },
    info: {
      main: '#747474',
    },
    text: {
      secondary: '#999999',
    },
  },
});
  


export default baseTheme;
