import React, { useEffect } from 'react';
import './styles.css';
import { FooterProps } from './index.types';
import useAxios from 'axios-hooks';
import logo from '../../../../assets/images/powered_by_tixserve.png';

const Footer: React.FC<FooterProps> = ({ splashscreen }) => {
  const axiosConfig = { url: `/api/Accounts/backendVersion` };
  const [{ data }, refetchData] = useAxios(axiosConfig, { manual: true });

  useEffect(() => {
    refetchData().catch((error) => {
      console.error(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <>
      {!splashscreen && (
        <div className={'footerBox'}>
          <p className={'primary'}>
            {' '}
            {
              <img className="poweredByTixserve" src={logo} alt="Logo"></img>
            }{' '}
            {<>&copy;</>} {currentYear}
          </p>
          <p className={'primary'}>Manager site v: {data?.version}</p>
        </div>
      )}
      {splashscreen && (
        <div className={'footerBox'}>
          <p className={'secondary'}>
            Powered by {'Tixserve -'} {currentYear}
          </p>
        </div>
      )}
    </>
  );
};

export default Footer;
