import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

export interface AuthState {
  token: string;
  tokenExpirationTime: number | null;
  userId: number | null;
  managerId: number | null;
};

const initialState: AuthState = {
  token: '',
  tokenExpirationTime: null,
  userId: null,
  managerId: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setTokenExpirationTime: (state, action: PayloadAction<number | null>) => {
      state.tokenExpirationTime = action.payload;
    },
    setUserId: (state, action: PayloadAction<number | null>) => {
      state.userId = action.payload;
    },
    setManagerId: (state, action: PayloadAction<number | null>) => {
      state.managerId = action.payload;
    },
    authReset: () => initialState,
  },
});

export const { setToken, setTokenExpirationTime , setUserId , setManagerId , authReset } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token; // todo: is this the same as a selector?

export default authSlice.reducer;
