import '../styles.css';

import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../Button';

const NotificationsLink: React.FunctionComponent = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
   
    <div className={ pathname === '/notifications' ? 'active' : '' } data-testid={ `sideNavMenu-pageName-NotificationLink` }>
      <Button name='App Notifications' linkTo='/notifications' icon='fa fa-mobile' />
    </div>
     
  );
};

export default NotificationsLink;
