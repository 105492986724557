import { API } from '../../../../api/api.types';

export interface AgentModalProps {
    headerText: string;
    submitLabel: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (agentDetails: API.Agent) => void;
    apiLoading: boolean;
    apiError: string | null | undefined;
    testIdSection: string;
    agentData?: API.Agent;
    agents?: API.Agent[];
    loading?: boolean;
  }


export const INITIAL_AGENT_DETAILS_STATE = {
  name: '',
  phoneNumber: '',
  allocatedTickets: 0,
  remainingTickets: 0,
};
