import './styles.css';

import { DeleteForeverOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import Swal from 'sweetalert2';
import useAxios from 'axios-hooks';
import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { DeleteAgentButtonProps } from './index.types';
import { DeleteAgent } from '../../../api/agentsApi/agents';

const DeleteAgentButton:React.FunctionComponent<DeleteAgentButtonProps> = ({ agent, handleSetTrigger, testId, index }) => {
  const account = useAppSelector(((state: RootState) => state.user.account));
  const accountId = account?.accountId;
  const [, refetch] =  useAxios(DeleteAgent(accountId, null), { manual: true });

  const deleteAgentConfirmation = () => {
    if(Number(agent.remainingTickets) === 0){
      Swal.fire({
        title: `Delete Agent`,
        text: `Are you sure you want to delete the agent permanently?`,
        icon: 'warning',
        showCancelButton: true, 
        confirmButtonColor: 'red',
        confirmButtonText: 'Delete',
      }).then((result) => { 
        if (result.isConfirmed) {
          const request = DeleteAgent(accountId, agent?.agentId);
          refetch(request).then(() => {
            Swal.fire({
              title: 'Deleted',
              text: `Agent successfully deleted`,
              icon: 'success',
              confirmButtonText: 'OK',
            });}).then(() => {
            handleSetTrigger();
          }).catch(() => {
            Swal.fire({
              title: 'Error',
              text: `There was an error deleting the agent`,
              icon: 'error',
              confirmButtonText: 'OK',
            });});
            
        } 
      });
    }else{
      Swal.fire({
        title: `Error`,
        text: `Agent can't be deleted as it has tickets for upcoming events`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    
  };
  return (
    <>
      <IconButton className="deleteButton" aria-label='delete' onClick={ deleteAgentConfirmation } data-testid={ `${testId}Table-deleteAgentButton-${index}` }>
        <DeleteForeverOutlined/>
      </IconButton>
    </>
    
  );
};

export default DeleteAgentButton;
