import './styles.css';

import { Fragment } from 'react';
import { TableRow, TableCell,TableBody,  Table } from '@mui/material';
import { Props } from './index.types';
import React from 'react';



const GridRow:React.FunctionComponent<Props> = ({ contentList, isActive, testId, index }) => {

  const classes = `row ${(isActive===false)? 'inactive-row':''}`;
  return (
    
    <Fragment>
      { contentList &&
      <TableRow className={ classes } data-testid={ `${testId}Table-dataRow-${index}` }>
        { contentList?.map((data: any) => (
          <Fragment key={ data.id }>
            { data.hidden !== true && <TableCell>
              { data.value.map((detail: any) => (
                <Table key={ detail.id }>
                  <TableBody>
                    <TableRow>
                      { detail.hidden !== true && <TableCell  rowSpan={ 1 }  className={ detail.class } data-testid={ `${detail.dataTestId}-${index}` }>{ detail.value }</TableCell> }
                    </TableRow>
                  </TableBody>
                </Table>
              )) } 
            </TableCell>
            }
          </Fragment>
        )) } 
      </TableRow> }
    </Fragment>
              
  );
};

export default GridRow;

