import './styles.css';

import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { AgentModalProps, INITIAL_AGENT_DETAILS_STATE } from './index.types';
import { HeadIcon } from '../../../core/popup/popupIcons';
import { API } from '../../../../api/api.types';
import TabModal, { ModalTab } from '../../../core/popup/TabModal';
import {
  ModalActionBar,
  ModalContentPanel,
  ModalInfoPanel,
} from '../../../core/popup/Modal';
import {
  getNationalNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from '../../../core/Phone/Phone';
import { AgentAssignForm } from './components/agentAssignForm';
import {
  INITIAL_RECIPIENT_STATE,
  RecipientState,
} from './components/agentAssignForm/index.types';

const validateAgent = (
  agentDetails: API.Agent,
  recipientDetails: RecipientState,
  agentData?: API.Agent,
): boolean => {
  const regex = /(^\S.*[a-zA-Z0-9_\s]+)/g;
  if (
    agentDetails.name.length === 0 ||
    agentDetails.phoneNumber.length === 0 ||
    !isValidPhoneNumber(agentDetails.phoneNumber) ||
    !agentDetails.name.match(regex)
  ) {
    return false;
  }

  if (recipientDetails.isAgentFound && agentDetails && agentData) {
    return false;
  }

  return true;
};

const AgentModal: React.FunctionComponent<AgentModalProps> = ({
  headerText,
  submitLabel,
  open,
  onClose,
  onSubmit,
  apiLoading,
  apiError,
  testIdSection,
  agentData,
  agents,
  loading,
}) => {
  const [agentDetails, setAgentDetail] = useState<API.Agent>(
    agentData
      ? {
          ...INITIAL_AGENT_DETAILS_STATE,
          name: agentData.name,
          phoneNumber: parsePhoneNumber(agentData.phoneNumber).nationalNumber!,
        }
      : INITIAL_AGENT_DETAILS_STATE,
  );

  const [recipientDetails, setRecipientDetail] = useState<RecipientState>(
    agentData
      ? {
          ...INITIAL_RECIPIENT_STATE,
          name: agentData.name,
          phoneNumber: agentData.phoneNumber,
          isAgentFound: false,
        }
      : INITIAL_RECIPIENT_STATE,
  );

  const [isAgentValid, setIsAgentValid] = useState(false);

  const handleAgentUpdate = (recipient: RecipientState) => {
    if (parsePhoneNumber(recipient.phoneNumber).countryCode) {
      setRecipientDetail({
        ...recipientDetails,
        name: recipient.name,
        phoneNumber: recipient.phoneNumber,
        countryDialCode: recipient.countryDialCode,
        isAgentFound: recipient.isAgentFound,
      });
      setAgentDetail({
        ...agentDetails,
        name: recipient.name,
        phoneNumber: recipient.phoneNumber,
      });
    } else {
      setRecipientDetail({
        ...recipientDetails,
        name: recipient.name,
        phoneNumber: getNationalNumber(
          recipient.countryDialCode + recipient.phoneNumber,
          recipient.countryDialCode,
        ),
        countryDialCode: recipient.countryDialCode,
        isAgentFound: recipient.isAgentFound,
      });
      setAgentDetail({
        ...agentDetails,
        name: recipient.name,
        phoneNumber: getNationalNumber(
          recipient.countryDialCode + recipient.phoneNumber,
          recipient.countryDialCode,
        ),
      });
    }
  };

  const handleClose = () => {
    if (!agentData) {
      setAgentDetail(INITIAL_AGENT_DETAILS_STATE);
      setRecipientDetail(INITIAL_RECIPIENT_STATE);
    }
    if (agentData) {
      setAgentDetail(agentData);
      setRecipientDetail(
        agentData
          ? {
              ...INITIAL_RECIPIENT_STATE,
              name: agentData.name,
              phoneNumber: agentData.phoneNumber,
              isAgentFound: false,
            }
          : INITIAL_RECIPIENT_STATE,
      );
    }
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(agentDetails);
    if (!agentData) {
      setAgentDetail(INITIAL_AGENT_DETAILS_STATE);
      setRecipientDetail(INITIAL_RECIPIENT_STATE);
    }
  };

  useEffect(() => {
    setIsAgentValid(validateAgent(agentDetails, recipientDetails, agentData));
  }, [agentDetails, recipientDetails, agentData]);

  return (
    <TabModal
      loaderState={apiLoading}
      open={open}
      onClose={handleClose}
      headerIcon={<HeadIcon />}
      testIdSection={testIdSection}
    >
      <ModalTab label="Manual">
        <ModalInfoPanel>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align="center" className="headerText-agentModal">
                {headerText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="left">
                Fill in the details of the agent you want to create/edit.
              </Typography>
            </Grid>
          </Grid>
        </ModalInfoPanel>
        <ModalContentPanel>
          <Grid spacing={1} container className="container">
            <AgentAssignForm
              initialRecipient={recipientDetails}
              onRecipientChanged={handleAgentUpdate}
              testIdSection={testIdSection}
              edit={!!agentData}
              agents={agents}
              loading={loading}
            />
          </Grid>
        </ModalContentPanel>
        <ModalActionBar>
          <Grid alignItems="center" justifyContent="flex-end" container>
            {apiError && (
              <Grid item xs={8} style={{ marginRight: 12 }}>
                <Typography variant="body1" color="error" align="left">
                  {apiError}
                </Typography>
              </Grid>
            )}
            <Grid item xs={3} style={{ marginRight: 12 }}>
              <Button
                disabled={apiLoading || !isAgentValid}
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                className="createButton-Modal"
                data-testid={`${testIdSection}-${
                  testIdSection === 'newAgentModal' ? 'manualTab-' : ''
                }submitButton`}
              >
                {' '}
                {submitLabel}
              </Button>
            </Grid>
          </Grid>
        </ModalActionBar>
      </ModalTab>
    </TabModal>
  );
};

export default AgentModal;
