import phoneNumberLib, * as phoneLib from 'google-libphonenumber';
import { countries } from '../CountryCodes';

const phoneNumberUtil = phoneNumberLib.PhoneNumberUtil.getInstance();

const getDefaultCountryCodeForDialCode = (dialCode: string | undefined) => {
  const countriesForDialCode = countries.filter((c: any) => c.dialCode === dialCode);
  if (countriesForDialCode.length === 1) {
    return countriesForDialCode[0].code;
  }
  return countriesForDialCode.find((c: any) => c.defaultCountryForDialCode === true)?.code;
};

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  try {
    const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
    return phoneNumberUtil.isValidNumber(parsedPhoneNumber);
  } catch (e) {
    return false;
  }
};

export const parsePhoneNumber = (phoneNumber: string | undefined): {
  nationalNumber: string | undefined;
  countryCode?: string;
} => {

  try {
    const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumber!);
    const nationalNumber = parsedPhoneNumber.getNationalNumber()?.toString() || '';
    const dialCode = parsedPhoneNumber.getCountryCode();
    const countryCode = getDefaultCountryCodeForDialCode(dialCode?`+${dialCode}`:'');

    return {
      nationalNumber,
      countryCode,
    };
  } catch (e) {
    const dialCode = countries.find((country: any) => country.dialCode === phoneNumber)?.dialCode;
    let nationalNumber = phoneNumber;
    let countryCode:string | undefined = '';
    if (dialCode) {
      nationalNumber = phoneNumber?.substring(dialCode.length, phoneNumber.length)!;
      countryCode = getDefaultCountryCodeForDialCode(dialCode);
    }

    return {
      nationalNumber,
      countryCode,
    };
  }
};

export const getNationalNumber = (phoneNumber: string, countryCode: string): string => {
  try {
    return phoneNumberUtil.format(
      phoneNumberUtil.parseAndKeepRawInput(phoneNumber!, countryCode),
      phoneLib.PhoneNumberFormat.E164,
    );
  } catch (e) {
    return '';
  }
};
