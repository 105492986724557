import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from '../../layout/Layout';
import SplashscreenLayout from '../../layout/SplashscreenLayout';
import ProtectedRoute from '../../routing/ProtectedRoutes';
import Loading from '../../loading';
import TicketImportProcessPage from '../../../tickets/ticketImportProcess';
import Agents from '../../../../pages/Agents';
import WithRole from '../../withRole';

const Managers = React.lazy(() => import('../../../../pages/Managers'));
const Maintenance = React.lazy(
  () => import('../../../../pages/MaintenanceMode'),
);
const Events = React.lazy(() => import('../../../../pages/Events'));
const SeasonTicketing = React.lazy(
  () => import('../../../../pages/SeasonTicketing'),
);
const Login = React.lazy(() => import('../../../../pages/Login'));
const Venues = React.lazy(() => import('../../../../pages/Venues'));
const Tickets = React.lazy(() => import('../../../../pages/Tickets'));
const TicketImport = React.lazy(() => import('../../../../pages/TicketImport'));
const Users = React.lazy(() => import('../../../../pages/Users'));
const Transfer = React.lazy(() => import('../../../../pages/Transfer'));
const Statistics = React.lazy(() => import('../../../../pages/Statistics'));
const NotFound = React.lazy(() => import('../../../../pages/NotFound'));
const Profile = React.lazy(() => import('../../../../pages/Profile'));
const EventEditor = React.lazy(() => import('../../../events/eventEditor'));
const AppHomeEditor = React.lazy(
  () => import('../../../events/appHome/appHomeEditor'),
);
const SeasonTicketingEditor = React.lazy(
  () => import('../../../seasonTicketing/seasonTicketingEditor'),
);
const VenueEditor = React.lazy(() => import('../../../venue/venueEditor'));
const Notifications = React.lazy(
  () => import('../../../../pages/Notifications/NotificationsPage'),
);
const NotificationsSender = React.lazy(
  () => import('../../../../pages/Notifications/NotificationsSendPage'),
);
const SmsNotification = React.lazy(
  () => import('../../../../pages/SmsNotification'),
);
const PublicActivation = React.lazy(
  () => import('../../../../pages/PublicActivation'),
);
const Router: React.FunctionComponent = () => {
  if (process.env.REACT_APP_MAINTENANCE_MODE === 'On') {
    return (
      <React.Suspense fallback={<Loading sx={{ bgcolor: 'white' }} />}>
        <Routes>
          <Route element={<SplashscreenLayout />}>
            <Route path="*" element={<Maintenance />} />
          </Route>
        </Routes>
      </React.Suspense>
    );
  }

  return (
    <React.Suspense fallback={<Loading sx={{ bgcolor: 'white' }} />}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/events/:id/publicActivation"
            element={<PublicActivation />}
          />
        </Route>
        <Route element={<SplashscreenLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<Layout />}>
          <Route element={<ProtectedRoute />}>
            <Route index element={<Navigate to="/tickets" />} />
            <Route
              path="/events"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <Events />
                </WithRole>
              }
            />
            <Route
              path="/events/:id"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <EventEditor />
                </WithRole>
              }
            />
            <Route
              path="/events/create"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <EventEditor />
                </WithRole>
              }
            />
            <Route
              path="/events/apphomeeditor"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <AppHomeEditor />
                </WithRole>
              }
            />
            <Route
              path="/seasonticketing"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <SeasonTicketing />
                </WithRole>
              }
            />
            <Route
              path="/seasonticketing/create"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <SeasonTicketingEditor />
                </WithRole>
              }
            />
            <Route
              path="/seasonticketing/:id"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <SeasonTicketingEditor />
                </WithRole>
              }
            />
            <Route
              path="/venues"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <Venues />
                </WithRole>
              }
            />
            <Route
              path="/venues/:id"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <VenueEditor />
                </WithRole>
              }
            />
            <Route
              path="/venues/create"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <VenueEditor />
                </WithRole>
              }
            />

            <Route
              path="/tickets"
              element={
                <WithRole roles={['support']} errorScreen={true}>
                  <Tickets />
                </WithRole>
              }
            />
            <Route
              path="/tickets/import"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <TicketImport />
                </WithRole>
              }
            />
            <Route
              path="/tickets/import/process"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <TicketImportProcessPage />
                </WithRole>
              }
            />
            <Route
              path="/tickets/:id"
              element={
                <WithRole roles={['support']} errorScreen={true}>
                  <Tickets />
                </WithRole>
              }
            />
            <Route
              path="/users"
              element={
                <WithRole roles={['support']} errorScreen={true}>
                  <Users />
                </WithRole>
              }
            />
            <Route
              path="/agents"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <Agents />
                </WithRole>
              }
            />
            <Route
              path="/transfer/:id"
              element={
                <WithRole roles={['support']} errorScreen={true}>
                  <Transfer />
                </WithRole>
              }
            />
            <Route
              path="/transfer/"
              element={
                <WithRole roles={['support']} errorScreen={true}>
                  <Transfer />
                </WithRole>
              }
            />
            <Route
              path="/statistics"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <Statistics />
                </WithRole>
              }
            />
            <Route
              path="/statistics/:id"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <Statistics />
                </WithRole>
              }
            />
            <Route
              path="/managers"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <Managers />
                </WithRole>
              }
            />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/smsnotification"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <SmsNotification />
                </WithRole>
              }
            />
            <Route
              path="/notifications"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <Notifications />
                </WithRole>
              }
            />
            <Route
              path="/notifications/send"
              element={
                <WithRole roles={[]} errorScreen={true}>
                  <NotificationsSender />
                </WithRole>
              }
            />
            <Route element={<NotFound />} />
          </Route>
          <Route />
          <Route />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default Router;
