import './styles.css';

import React from 'react';

import 'font-awesome/css/font-awesome.min.css';
import SideNavList from './SideNavList';
import SideNavTop from './SideNavTop';

const SideNavBar: React.FunctionComponent = () => {
  return (
    <div className='sideNav'>
      <div className='top'>
        <SideNavTop/>
      </div>
      <div className='list'>
        <SideNavList />
      </div>
    </div>
  );
};

export default SideNavBar;
