import { StepIconProps, Stepper } from '@mui/material';
import React from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Check } from '@mui/icons-material';

interface StepperProps {
  steps: string[]
  activeStep: number
  testId: string;
}

const CustomStepper:React.FunctionComponent<StepperProps> = ({ steps, activeStep, testId }) => {

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#784af4',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    }),
  );

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={ { active } } className={ className }>
        { completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        ) }
      </QontoStepIconRoot>
    );
  }

  // const steps = ['Upload file', 'Validate', 'Confirm'];

  return (
   
    <Stepper alternativeLabel activeStep={ activeStep } connector={ <QontoConnector /> }>
      { steps.map((label) => (
        <Step key={ label }>
          <StepLabel data-testid={ `${testId}-custom-stepper-${label.replace(/ /g,'-').toLowerCase()}` } StepIconComponent={ QontoStepIcon }>{ label }</StepLabel>
        </Step>
      )) }
    </Stepper>
      
   
  );
};

export default CustomStepper;
