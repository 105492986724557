import React from 'react';
import { IconProps } from '../index.types';


export const NotifyIcon: React.FC<IconProps> = ({
  backgroundColor,
  patternColor,
  size,
}) => (
  <svg width={ size || '120' } height={ size || '120' } viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z" fill={ backgroundColor || '#007AB3' } />
    <path d="M60.02 28.02C53.691 28.02 47.5042 29.8968 42.2418 33.413C36.9794 36.9292 32.8779 41.9269 30.4559 47.7742C28.0339 53.6214 27.4002 60.0555 28.6349 66.2629C29.8696 72.4703 32.9173 78.1722 37.3926 82.6474C41.8679 87.1227 47.5698 90.1704 53.7771 91.4052C59.9845 92.6399 66.4187 92.0062 72.2659 89.5842C78.1131 87.1622 83.1109 83.0606 86.6271 77.7983C90.1433 72.5359 92.02 66.349 92.02 60.02C92.02 59.651 91.8734 59.297 91.6125 59.0361C91.3515 58.7751 90.9976 58.6285 90.6285 58.6285C90.2595 58.6285 89.9056 58.7751 89.6446 59.0361C89.3836 59.297 89.237 59.651 89.237 60.02C89.2451 66.9362 86.8022 73.6315 82.342 78.9174C77.8819 84.2033 71.6931 87.7379 64.8741 88.8937C58.0552 90.0494 51.0473 88.7516 45.0945 85.2306C39.1417 81.7096 34.629 76.1932 32.3576 69.6606C30.0861 63.1281 30.2029 56.002 32.6871 49.5473C35.1713 43.0927 39.8623 37.7271 45.9273 34.403C51.9923 31.0789 59.0389 30.0114 65.8163 31.3899C72.5937 32.7684 78.6635 36.5038 82.948 41.933H73.933C73.564 41.933 73.2101 42.0796 72.9491 42.3406C72.6881 42.6015 72.5415 42.9555 72.5415 43.3245C72.5415 43.6936 72.6881 44.0475 72.9491 44.3085C73.2101 44.5694 73.564 44.716 73.933 44.716H87.846V30.803C87.846 30.434 87.6994 30.08 87.4385 29.8191C87.1775 29.5581 86.8236 29.4115 86.4545 29.4115C86.0855 29.4115 85.7316 29.5581 85.4706 29.8191C85.2096 30.08 85.063 30.434 85.063 30.803V40.12C82.0714 36.3453 78.2647 33.2961 73.9279 31.2007C69.5911 29.1053 64.8365 28.018 60.02 28.02Z" fill={ patternColor || 'white' } stroke={ patternColor || 'white' } />
  </svg>
);
