const GetContainerConfig = (
  container: string | undefined,
  formData: object | undefined,
): object => {
  return {
    method: 'POST',
    url: `/api/containers/${container}/upload`,
    data: formData,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };
};

export { GetContainerConfig };
