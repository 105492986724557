import { Children, Component, ComponentType, ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const filterChildren: ChildrenFilterer = (
  children: ReactNode,
  componentTypes: ComponentType[],
) => {
  const filtered: Component[][] = componentTypes.map<Component[]>(() => []);
  Children.forEach(children, (child) => {
    if (child && typeof child === 'object' && ('type' in child)) {
      const typeIndex = componentTypes.findIndex((type) => child.type === type);
      if (typeIndex >= 0) {
        filtered[typeIndex].push(child as unknown as Component);
      }
    }
  });
  return filtered;
};
