import { Link } from 'react-router-dom';

const AdminAreaLink: React.FC = () => {
  return (
    <Link to="/managers" data-testid={`topNavMenu-administration-area-link`}>
      <i className="fa fa-users"></i>&nbsp;Portal Users
    </Link>
  );
};

export default AdminAreaLink;
AdminAreaLink.displayName = 'PORTAL_USER';
