import './styles.css';

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../../../api/loginApi/user';
import { authReset } from '../../../../store/authSlice';
import { userReset } from '../../../../store/UserSlice';
import { useAppDispatch } from '../../../../store/hooks';
import WithFeature from '../../WithFeature';
import AdminAreaLink from './adminArea';
import WithRole from '../../withRole';

export const TopNavBar: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(authReset());
    dispatch(userReset());
    logout();
  };

  return (
    <Fragment>
      <ul className="nav navbar-top-links navbar-right">
        <li>
          <WithRole roles={[]}>
            <WithFeature>
              <AdminAreaLink></AdminAreaLink>
            </WithFeature>
          </WithRole>
        </li>
        <li>
          <Link to="/profile" data-testid={`topNavMenu-profileLink`}>
            <i className="fa fa-user"></i>&nbsp;Profile
          </Link>
        </li>
        <li>
          <Link
            to="/login"
            onClick={handleClick}
            data-testid={`topNavMenu-logoutLink`}
          >
            <i className="fa fa-sign-out"></i>&nbsp;Logout
          </Link>
        </li>
      </ul>
    </Fragment>
  );
};
