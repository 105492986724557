import { createSlice } from '@reduxjs/toolkit';
import { API } from '../../api/api.types';

export interface AgentState {
  agentList: API.Agent[];
}

export const initialState: AgentState = {
  agentList: [],
};

export const agentSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setAgents: (state, action) => {
      state.agentList = action.payload;
    },
  },
});

export const { setAgents } = agentSlice.actions;

export default agentSlice.reducer;
