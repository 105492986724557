import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmImport } from '../../../api/ticketApi/ticketImport';
import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import Loading from '../../core/loading';
import { TicketImportSummarization } from './types';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { map } from 'lodash';
import { isValidEmail } from '../../../core/utils/isValidEmail';
import Swal from 'sweetalert2';

export const ProcessConfirm: React.FC<{
  fileId: number | null;
  importSummarization: TicketImportSummarization;
}> = ({ fileId, importSummarization }) => {
  const account = useAppSelector((state: RootState) => state.user.account);
  const userEmail = useAppSelector((state: RootState) => state.user.userEmail);

  const navigate = useNavigate();
  const [onLoading, setOnLoading] = useState<boolean>(false);
  const [emailNotification, setEmailNotification] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(userEmail);

  const accountId = account?.accountId;
  const [, refetch] = useAxios<{ isSuccess: boolean }>(
    ConfirmImport(accountId, null),
    { manual: true },
  );

  const testId = 'ticketimport-confirm';

  const handleConfirm = async () => {
    setOnLoading(true);

    try {
      const { data } = await refetch(
        ConfirmImport(accountId, { fileId, email }),
      );

      if (!data.isSuccess) {
        Swal.fire({
          title: `Ticket Importer Error`,
          text: `Failed to upload CSV/Excel file. Double check that all the Foreign Ticket IDs & QR codes are unique.`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/tickets/import');
        });
      } else {
        navigate('/tickets/import');
      }
    } catch (error) {
      Swal.fire({
        title: `Ticket Importer Error`,
        text: `Failed to upload CSV/Excel file. Double check that all the Foreign Ticket IDs & QR codes are unique.`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/tickets/import');
      });
    }
  };

  const isValidEmailAddress = isValidEmail(email);

  const getSummarization = () => {
    return map(importSummarization, (item, index) => {
      return (
        <Accordion>
          <AccordionSummary
            data-testid={`${testId}Page-accordion-summary`}
            expandIcon={<ExpandMoreOutlined />}
            aria-controls={`${index}-content`}
            id={`${index}-header`}
            aria-expanded="true"
          >
            <Typography data-testid={`${testId}Page-accordion-summary-text`}>
              <strong>{item.ticketCount} ticket(s)</strong> will be created for
              the following event: <strong>{item.eventName}</strong> (
              {item.eventCode}){' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails data-testid={`${testId}Page-accordion-details`}>
            {map(item.ticketTypes, (item, index) => {
              return (
                <Typography
                  key={index}
                  data-testid={`${testId}Page-accordion-details-text`}
                >
                  <strong>{item.ticketTypeName}</strong> ({item.ticketTypeCode})
                  - {item.ticketCount} ticket(s)
                </Typography>
              );
            })}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <Grid container justifyContent={'center'} mt={12}>
      {onLoading && <Loading />}
      {!onLoading && (
        <Grid data-testid={`${testId}Page-grid-summary`} item xs={12} lg={6}>
          {getSummarization()}
        </Grid>
      )}
      <Grid
        data-testid={`${testId}Page-email-grid`}
        container
        justifyContent={'center'}
        spacing={2}
        mt={3}
      >
        {!onLoading && (
          <>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid={`${testId}Page-email-checkbox`}
                      onChange={(e) => {
                        setEmailNotification(e.target.checked === true);
                      }}
                    />
                  }
                  label="I want email notification when the process is completed"
                />
              </FormGroup>
              {emailNotification && (
                <TextField
                  data-testid={`${testId}Page-email`}
                  error={!isValidEmailAddress}
                  helperText={
                    !isValidEmailAddress ? 'Invalid Email Address' : ''
                  }
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid container justifyContent={'center'} spacing={2} mt={6}>
        <Grid item xs={12} lg={3}>
          <Button
            onClick={() => {
              navigate('/tickets/import');
            }}
            className="textButton"
            color="primary"
            disabled={onLoading}
            fullWidth={true}
            variant="contained"
            data-testid={`${testId}Page-restart-Button`}
          >
            Restart
          </Button>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button
            onClick={handleConfirm}
            className="textButton"
            color="success"
            disabled={onLoading || (emailNotification && !isValidEmailAddress)}
            fullWidth={true}
            variant="contained"
            data-testid={`${testId}Page-confirm-Button`}
          >
            Confirm & start import
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
