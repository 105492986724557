import { createSlice } from '@reduxjs/toolkit';
import { VenueState } from './index.types';

export const initialState: VenueState = {
  venueValues: null,
  changed: false,
  refetchVenues: true,
  saveDisabled: false,
};

export const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    setVenue: (state, action) => {
      state.venueValues = { ...action.payload };
    },
    resetVenue: () => initialState,
    setChanged: (state, action) => {
      state.changed = action.payload;
    },
    setRefetchVenues: (state, action) => {
      state.refetchVenues = action.payload;
    },
    setSaveDisabled: (state, action) => {
      state.saveDisabled = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setVenue,
  resetVenue,
  setChanged,
  setSaveDisabled,
  setRefetchVenues,
} = venueSlice.actions;

export default venueSlice.reducer;
