// own module as this will be used elsewhere - on app load, etc
// maybe on getting a new token - set it in local, then retrieve

import { logout } from '../../api/loginApi/user';

const getLocalToken = (): string | null => {
  const time = new Date().getTime();

  if (localStorage.tokenExpirationTime < time) {
    logout();
  }

  return localStorage.getItem('token');
};

export const getLocalUserId = (): number | null => {
  return Number(localStorage.getItem('currentUserId'));
};

export const getLocalManagerId = (): number | null => {
  return Number(localStorage.getItem('currentManagerId'));
};

export default getLocalToken;
