import React, { FormEvent, useCallback } from 'react';
import { FormComponentProps } from './index.types';

const FormComponent: React.FunctionComponent<FormComponentProps> = ({ children, onSubmit, ...props }) => {
  const onSubmitPreventDefault = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  }, [onSubmit]);
  return (
    <form onSubmit={ onSubmitPreventDefault } { ...props }>
      { children }
    </form>
  );
};

export default FormComponent;
