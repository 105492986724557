import './styles.css';

import React from 'react';
import SideNavBar from '../SideNavBar';
import Footer from '../Footer';
import Header from '../Header';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const Layout: React.FunctionComponent = () => {
  return (
    <div className="app-layout">
      <ToastContainer />
      <SideNavBar />
      <div className="main-content">
        <Header />
        <main className="wrapper-content">
          <Outlet />
        </main>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
