import './styles.css';
import React from 'react';
import GridRow from '../../core/grid/gridRow';
import { ContentArray } from '../../core/grid/gridRow/index.types';
import { PreImportTicketExtended } from '../ticketImportProcess/types';

const TicketValidationTableContent:React.FC<{preTicket: PreImportTicketExtended}> = ({ preTicket }) => {

  const testId = 'ticketValidation';

  const getFields = (customFields: PreImportTicketExtended['customFields']) => {
    return Object.keys(customFields).map(key => <>{ key }: { customFields[key] } <br /></>);
  };


  const contentList:ContentArray[] = [
    { id: 'rowNumberColumn', value: [
      { id: 'rowNumber', class: 'small', dataTestId: `${testId}Table-rowNumber`, value: preTicket.rowNumber }] },
    { id: 'ownerNameColumn', value: [
      { id: 'ownerName', class:'', value: preTicket['Owner Name'] || preTicket['agentName']  },  
      { id: 'ownerPhoneNumber', class: 'small', dataTestId: `${testId}Table-ownerPhoneNumber`, value: preTicket['Owner Phone Number'] || preTicket['Agent Phone Number'] }] },
    { id: 'eventColumn', value: [
      { id: 'eventName', class: 'blueText', dataTestId: `${testId}Table-eventName`, value:  preTicket.eventName }, 
      { id: 'eventCode', class: 'smallGrey', dataTestId: `${testId}Table-eventCode`, value:  preTicket['Event Code'] }] },
    { id: 'ticketTypeColumn', value: [
      { id: 'ticketTypeName', class: 'blueText', dataTestId: `${testId}Table-ticketTypeName'`, value: preTicket.ticketTypeName }, 
      { id: 'ticketTypeCode', class: 'smallGrey', dataTestId: `${testId}Table-ticketTypeCode`, value: preTicket['Ticket Type Code'] }] },
    { id: 'ticketCustomFields', value: [
      { id: 'ticketCustomField', class: 'smallGrey', dataTestId: `${testId}Table-ticketCustomFields`, value: getFields(preTicket.customFields) }] },
    { id: 'keyColumns', value: [
      { id: 'QRCode', class: 'smallGrey', dataTestId: `${testId}Table-QRCode`, value: `qr: ${preTicket['Custom QR Content']}` }, 
      { id: 'foreignKey', class: 'small', dataTestId: `${testId}Table-foreignKey`, value: `fg: ${preTicket['Foreign Ticket ID']}` }] },
    { id: 'ticketStatusColumn', value: [
      { id: 'ticketStatus', class: '', dataTestId: `${testId}Table-ticketStatusContainer`, value: <div className='ticketStatusColumn'>{
        <ul className='validationError'>{ preTicket.messages.map(m => <li>{ m }<br /></li>) }</ul>
      }
      </div> }] },
  ];
 
  return (
    <GridRow  contentList= { contentList } isActive={ true } testId={ testId } index={ preTicket.rowNumber }/>
  );
};

export default TicketValidationTableContent;
