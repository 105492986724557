import Routes from './components/core/routing/Routes';
import checkLocalToken, {
  getLocalUserId,
  getLocalManagerId,
} from './core/helpers/checkLocalStorage';
import { setToken, setUserId, setManagerId } from './store/authSlice';
import { useAppDispatch } from './store/hooks';
import './App.css';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import theme from './stylesheet';
import { configureApiToken } from './api/api';
import { enableSentry, initSentry } from './core/utils/sentry';
import * as Sentry from '@sentry/react';

initSentry();
function App() {
  // get the local token if it's there
  // todo: check expiration time, etc... lots to do here
  const dispatch = useAppDispatch();
  const localToken = checkLocalToken();
  if (localToken) {
    dispatch(setToken(localToken));
    dispatch(setUserId(getLocalUserId()));
    dispatch(setManagerId(getLocalManagerId()));
    configureApiToken(localToken);
    enableSentry(getLocalUserId());
  }

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default Sentry.withProfiler(App);
