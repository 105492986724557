import './styles.css';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../Button';
import SeasonButtonLink from '../SeasonButton';
import NotificationsLink from './NotificationsLink';
import WithFeature from '../../../WithFeature';
import WithRole from '../../../withRole';

const SideNavList: React.FunctionComponent = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div>
      <WithRole roles={[]}>
        <div
          className={pathname === '/events' ? 'active' : ''}
          data-testid={`sideNavMenu-pageName-eventsLink`}
        >
          <Button name="Events" linkTo="/events" icon="fa-calendar-o" />
        </div>
      </WithRole>
      <WithRole roles={[]}>
        <WithFeature>
          <SeasonButtonLink></SeasonButtonLink>
        </WithFeature>
      </WithRole>
      <WithRole roles={[]}>
        <div
          className={pathname === '/venues' ? 'active' : ''}
          data-testid={`sideNavMenu-pageName-venuesLink`}
        >
          <Button name="Venues" linkTo="/venues" icon="fa-university" />
        </div>
      </WithRole>
      <WithRole roles={['support']}>
        <div
          className={pathname === '/tickets' ? 'active' : ''}
          data-testid={`sideNavMenu-pageName-ticketsLink`}
        >
          <Button name="Tickets" linkTo="/tickets" icon="fa-ticket" />
        </div>
      </WithRole>
      <WithRole roles={[]}>
        <div
          className={
            pathname === '/tickets/import' ||
            pathname === '/tickets/import/process'
              ? 'active'
              : ''
          }
          data-testid={`sideNavMenu-pageName-ticketsLink`}
        >
          <Button
            name="Ticket Importer"
            linkTo="/tickets/import"
            icon="fa-ticket"
          />
        </div>
      </WithRole>
      <WithRole roles={['support']}>
        <div
          className={pathname === '/users' ? 'active' : ''}
          data-testid={`sideNavMenu-pageName-usersLink`}
        >
          <Button name="Users" linkTo="/users" icon="fa-user" />
        </div>
      </WithRole>
      <WithRole roles={[]}>
        <div
          className={pathname === '/agents' ? 'active' : ''}
          data-testid={`sideNavMenu-pageName-agentsLink`}
        >
          <Button name="Agents" linkTo="/agents" icon="fa-user-circle" />
        </div>
      </WithRole>
      <WithRole roles={[]}>
        <NotificationsLink />
      </WithRole>
      <WithRole roles={[]}>
        <div
          className={pathname === '/smsnotification' ? 'active' : ''}
          data-testid={`sideNavMenu-pageName-smsNotificationLink`}
        >
          <Button
            name="SMS Notification"
            linkTo="/smsnotification"
            icon="fa fa-commenting-o"
          />
        </div>
      </WithRole>
      <WithRole roles={[]}>
        <div
          className={pathname === '/statistics' ? 'active' : ''}
          data-testid={`sideNavMenu-pageName-statisticsLink`}
        >
          <Button name="Statistics" linkTo="/statistics" icon="fa-bar-chart" />
        </div>
      </WithRole>
    </div>
  );
};

export default SideNavList;
