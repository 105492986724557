import { createSlice } from '@reduxjs/toolkit';

const INITIAL_CONFIG: string[] = [];

const initialState = {
  configLocal: INITIAL_CONFIG,
  countryCode: 'AF',
};

export const configSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.configLocal = Object.keys(action.payload);
      state.countryCode =
        action.payload['COUNTRY_CODE'] || initialState.countryCode;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
  },
});

export const { setConfig } = configSlice.actions;
export const { setCountryCode } = configSlice.actions;
export default configSlice.reducer;
