import './styles.css';

import React, { Fragment } from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { TopNavBar } from '../TopNavBar';

const Header: React.FunctionComponent = () => {
  const currentLocation = useSelector(
    (state: RootState) => state.breadcrumb.currentLocation,
  );
  const { pathname } = useLocation();

  let location: Array<string> = [];
  let title = '';

  const patchMatches = [
    {
      match: /tickets\/import/,
      title: 'Ticket Importer',
    },
  ];

  if (currentLocation) {
    location = currentLocation.split('/').filter((el) => el !== '');
    if (location.length) {
      title =
        location[location.length - 1].charAt(0).toUpperCase() +
        location[location.length - 1].slice(1);
    }
  }

  const pathMatch = patchMatches.find((path) => pathname.match(path.match));
  if (pathMatch) {
    title = pathMatch.title;
  }

  return (
    <Fragment>
      <Grid
        container
        className="row wrapper border-bottom white-bg page-heading"
      >
        <Grid item lg={7} md={6} xs={11} className="grid-item">
          <h2>{title}</h2>
          <Breadcrumbs className="breadcrumb">
            <Link className="custom-link" to="/events">
              Administration{' '}
            </Link>
            {location.filter(String).map((loc: string, i: number) => {
              if (location.length - 1 === i) {
                return (
                  <Typography key={i}>
                    {' '}
                    {loc.charAt(0).toUpperCase() + loc.slice(1)}
                  </Typography>
                );
              }
              return (
                <Link
                  key={i}
                  className="custom-link"
                  to={`/${loc}`}
                  data-testid={`header-breadcrumbs-${loc}`}
                >
                  {loc.charAt(0).toUpperCase() + loc.slice(1)}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
          <TopNavBar />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Header;
