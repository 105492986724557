import {
  Table,
  TableBody,
  TablePagination,
  TableRow,
  Paper,
  Grid,
  TableCell,
} from '@mui/material';
import useAxios from 'axios-hooks';
import React, { Fragment, useEffect, useState } from 'react';
import {
  GetAgentsConfig,
  GetAgentsCountConfig,
} from '../../../api/agentsApi/agents';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import GridHead from '../../core/grid/gridHead';
import SearchBar from '../../core/SearchBar';
import { useLocation } from 'react-router-dom';
import AgentTableContent from '../AgentTableContent';
import NewAgentModal from '../agentModal/NewAgentModal';
import Loading from '../../core/loading';
import { API } from '../../../api/api.types';
import { setAgents } from '../../../store/agentSlice';
const AgentTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const agents = useAppSelector((state: RootState) => state.agents.agentList);

  const search = useLocation().search;

  const [agentId, setAgentId] = useState<string | null>(null);
  const [page, setPage] = React.useState(1);
  const [orderColumn, setOrderColumn] = React.useState('agentId');
  const [orderDirection, setOrderDirection] = React.useState<
    'desc' | 'asc' | false
  >('desc');
  const [isActive] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [query, setQuery] = React.useState('');
  const [trigger, setTrigger] = useState(false);
  const account = useAppSelector((state: RootState) => state.user.account);
  const accountId = account?.accountId;

  const [{ loading: agentLoading }, refetchAgents] = useAxios(
    GetAgentsConfig(
      accountId,
      rowsPerPage,
      page,
      orderColumn,
      orderDirection,
      isActive,
      query,
      agentId,
    ),
    { manual: !accountId },
  );
  const [{ data: count, loading: countLoading }, refetchCount] = useAxios(
    GetAgentsCountConfig(accountId, isActive, query, agentId),
    { manual: !accountId },
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const headerList = [
    { id: 'agentId', label: '#', sort: true },
    { id: 'name', label: 'Name', sort: true },
    { id: 'createdAt', label: 'Joined At', sort: true },
    {
      id: 'allocatedTickets',
      label: 'Allocated Tickets',
      class: 'align-numbers',
    },
    {
      id: 'remainingTickets',
      label: 'Remaining Tickets',
      class: 'align-numbers',
    },
  ];

  useEffect(() => {
    if (new URLSearchParams(search).get('agentId') !== agentId) {
      setAgentId(new URLSearchParams(search).get('agentId'));
    } else if (accountId) {
      refetchAgents()
        .then((res) => {
          if (res?.data) {
            dispatch(setAgents(res.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
      refetchCount().catch((error) => {
        console.log(error);
      });
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderDirection,
    orderColumn,
    isActive,
    query,
    accountId,
    rowsPerPage,
    search,
    agentId,
  ]);

  useEffect(() => {
    if (accountId) {
      refetchAgents()
        .then((res) => {
          if (res?.data) {
            dispatch(setAgents(res.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
      refetchCount().catch((error) => {
        console.log(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, trigger]);

  const changeOrder = (property: keyof any) => {
    if (property.toString() === orderColumn) {
      if (orderDirection === 'desc') {
        setOrderDirection('asc');
      } else {
        setOrderDirection('desc');
      }
    } else {
      setOrderColumn(property.toString());
      setOrderDirection('asc');
    }
  };

  const triggerSearch = (queryString: string) => {
    setQuery(queryString);
    setTrigger(!trigger);
  };

  const handleSetTrigger = () => {
    setTrigger(!trigger);
  };

  const loading = agentLoading || countLoading;

  // specify data test id attribute for core components used in multiple places
  const testId = 'agents';

  return (
    <Fragment>
      <Grid container>
        <Grid item lg={5}>
          <SearchBar query={query} setQuery={triggerSearch} testId={testId} />
        </Grid>
        <Grid container item lg={7} md={2} justifyContent="flex-end">
          <NewAgentModal
            setTrigger={setTrigger}
            trigger={trigger}
            testId={testId}
          />
        </Grid>
      </Grid>
      <Paper className="table-overflow">
        <Table>
          <GridHead
            list={headerList}
            orderColumn={orderColumn}
            orderDirection={orderDirection}
            changeOrder={changeOrder}
            testId={testId}
          />
          {!loading && (
            <TableBody className="body">
              {agents?.map((agentData: API.Agent, index: number) => (
                <AgentTableContent
                  key={agentData.agentId}
                  agent={agentData}
                  index={index}
                  testId={testId}
                  handleSetTrigger={handleSetTrigger}
                />
              ))}
            </TableBody>
          )}
        </Table>
        <Table>
          {loading && (
            <TableBody>
              <TableRow className="body">
                <TableCell rowSpan={1}>
                  <Loading
                    size={70}
                    sx={{
                      marginBottom: agents.length % 2 === 0 ? '70px' : '0px',
                      marginTop: agents.length % 2 === 1 ? '70px' : '0px',
                      bgcolor: 'white',
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              {count && (
                <TablePagination
                  colSpan={headerList.length + 1}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={count?.count}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  data-testid={`${testId}Table-paginationContainer`}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
};

export default AgentTable;
