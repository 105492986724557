import React from 'react';
import { IconProps } from '../index.types';

const AndroidIcon: React.FC<IconProps> = ({
  backgroundColor = 'none',
  patternColor = 'black',
  size = 22,
}) => (
  <svg width={ size } height={ size * (27 / 22) } viewBox="0 0 22 27" fill={ backgroundColor } xmlns="http://www.w3.org/2000/svg">
    <path d="M5.67773 0.015745C5.58701 0.0178306 5.49857 0.0445639 5.42188 0.0930796C5.34519 0.141595 5.28316 0.210065 5.24241 0.291149C5.20167 0.372234 5.18376 0.462877 5.1906 0.553364C5.19744 0.643851 5.22878 0.730771 5.28125 0.804807L6.75977 2.94153C5.34017 4.02942 4.34588 5.64553 4.08008 7.50012H17.9199C17.6541 5.64553 16.6598 4.02942 15.2402 2.94153L16.7188 0.804807C16.772 0.729689 16.8034 0.641341 16.8096 0.549498C16.8158 0.457655 16.7965 0.36588 16.7539 0.284285C16.7113 0.20269 16.647 0.134442 16.5681 0.0870598C16.4892 0.0396773 16.3987 0.0149995 16.3066 0.015745C16.2255 0.0164922 16.1458 0.0369755 16.0743 0.0754268C16.0028 0.113878 15.9418 0.169142 15.8965 0.236448L14.4062 2.3927C13.3983 1.82785 12.2388 1.50012 11 1.50012C9.76121 1.50012 8.60175 1.82785 7.59375 2.3927L6.10352 0.236448C6.05672 0.16693 5.99321 0.110287 5.91881 0.0717225C5.84441 0.0331581 5.76151 0.0139094 5.67773 0.015745ZM8 3.50012C8.552 3.50012 9 3.94812 9 4.50012C9 5.05212 8.552 5.50012 8 5.50012C7.448 5.50012 7 5.05212 7 4.50012C7 3.94812 7.448 3.50012 8 3.50012ZM14 3.50012C14.552 3.50012 15 3.94812 15 4.50012C15 5.05212 14.552 5.50012 14 5.50012C13.448 5.50012 13 5.05212 13 4.50012C13 3.94812 13.448 3.50012 14 3.50012ZM1 9.50012C0.448 9.50012 0 9.94812 0 10.5001V18.5001C0 19.0521 0.448 19.5001 1 19.5001C1.552 19.5001 2 19.0521 2 18.5001V10.5001C2 9.94812 1.552 9.50012 1 9.50012ZM4 9.50012V19.5001C4 20.6051 4.895 21.5001 6 21.5001V25.0001C6 25.8281 6.672 26.5001 7.5 26.5001C8.328 26.5001 9 25.8281 9 25.0001V21.5001H13V25.0001C13 25.8281 13.672 26.5001 14.5 26.5001C15.328 26.5001 16 25.8281 16 25.0001V21.5001C17.105 21.5001 18 20.6051 18 19.5001V9.50012H4ZM21 9.50012C20.448 9.50012 20 9.94812 20 10.5001V18.5001C20 19.0521 20.448 19.5001 21 19.5001C21.552 19.5001 22 19.0521 22 18.5001V10.5001C22 9.94812 21.552 9.50012 21 9.50012Z" fill={ patternColor } />
  </svg>
);

export { AndroidIcon };
