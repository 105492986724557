

const get2FASecretConfig =  () :object  => {
  return { method:'GET', url: `/api/Managers/2fa/secret` };
};

const attach2FAConfig =  () :object  => {
  return { method:'POST', url: `/api/Managers/2fa/attach` };
};

const updateUserPasswordConfig = ( data: object ):object => {
  return { method:'PUT', url: `/api/Managers/changePassword`, data };
};

const updateDefaultAccount = ( id: number|null, data: {toAccountId:number|null, fromAccountId:number|null} ):object => {
  return { method:'PUT', url: `/api/Managers/${id}/updateAccount`, data };
  
};

  
export {
  get2FASecretConfig,
  attach2FAConfig,
  updateUserPasswordConfig,
  updateDefaultAccount
};
