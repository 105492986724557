import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
const env =
  process.env.NODE_ENV === 'production'
    ? process.env.NODE_ENV.toLocaleUpperCase()
    : 'DEV';
let shouldSendData = process.env.NODE_ENV === 'production' ? true : false;
const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

export const initSentry = () => {
  const isDev = process.env.NODE_ENV === 'production' ? false : true;
  if (isDev) {
    console.log('In Development mode sentry not initialised');
    return;
  }

  if (!process.env.REACT_APP_SENTRY_DSN) {
    console.log('Sentry DSN variable is not set');
    return;
  }
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: env,
      release: 'admin-portal-release',
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['localhost', `${publicUrl.origin}`],
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      sampleRate: 0.4,
      maxBreadcrumbs: 10,
      tracesSampleRate: 0.4, // Capture 100% of the transactions, reduce in production!
      beforeSend(event) {
        if (shouldSendData) {
          return event;
        }
        return null;
      },
    });
    Sentry.setTag('Brand', 'Tixserve Admin Portal');
  } catch (err) {
    throw err;
  }
};

export const sentryCaptureException = (
  logLevel: Sentry.SeverityLevel,
  error: Error,
  message?: string,
) => {
  try {
    Sentry.configureScope((scope) => {
      scope.setLevel(logLevel);
      if (message) {
        scope.setFingerprint([message]);
      }
    });
    if (message) {
      Sentry.captureMessage(message);
    }
    Sentry.captureException(error);
  } catch (error) {
    throw error;
  }
};

export const enableSentry = (userId?: number | null) => {
  const isDev = process.env.NODE_ENV === 'production' ? false : true;
  if (isDev) {
    console.log('In Development mode sentry not enable');
    return;
  }

  try {
    if (userId) {
      shouldSendData = true;
      Sentry.setUser({ id: `${userId}` });
    }
  } catch (error) {
    throw error;
  }
};
