import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';


import { createSimpleComponentType } from '../../../../core/utils/createSimpleComponentType';
import { filterChildren } from '../../../../core/utils/filterChildren';

import Modal, { ModalActionBar, ModalContentPanel, ModalInfoPanel, ModalTopBar } from '../Modal';
import { ModalProps } from '../Modal/index.types';
export const ModalTab = createSimpleComponentType<{ label: string }>();

type TabModalProps = ModalProps

const TabModal: React.FC<React.PropsWithChildren<TabModalProps>> = ({
  children,
  open,
  onClose,
  headerIcon,
  loaderState,
  testIdSection,
}) => {
  const [selectedTab, selectTab] = useState(0);
  const [modalTabs] = filterChildren(children, [ModalTab]);
  const tabs = React.Children.map(modalTabs, ({ props: { label } }) => (
    <Tab label={ label } data-testid={ `${testIdSection}-tabLabel-${label}` } />
  ));
  const selectedModalTab = modalTabs[selectedTab];
  const [[info], [content], [actionBar]] = filterChildren(
    selectedModalTab.props.children,
    [ModalInfoPanel, ModalContentPanel, ModalActionBar],
  );
  return (
    <Modal
      open={ open }
      onClose={ onClose }
      headerIcon={ headerIcon }
      loaderState={ loaderState }
      testIdSection={ testIdSection }
    >
      { info }
      {
        modalTabs.length > 1 && (
          <ModalTopBar>
            <Tabs value={ selectedTab } onChange={ (_event, value) => selectTab(value) } centered>
              { tabs }
            </Tabs>
          </ModalTopBar>
        )
      }
      <ModalContentPanel>
        { content }
      </ModalContentPanel>
      { actionBar }
    </Modal>
  );
};

export default TabModal;
