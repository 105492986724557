const GetAgentsNotAccountId =  (id: number | undefined, text: string | null):object  => {
  const parameter:{[k: string]: any} = {};
  parameter.text= text;
  return { url: `/api/Accounts/${id}/agentsNotAccountId`,
    params: parameter,
  }; 
};

const GetAgentsConfig =  (
  accountId: number | undefined, 
  limit: number | null, 
  page: number | null, 
  order: string | null | undefined, 
  orderDirection: ('desc' | 'asc' | false), 
  isActive: boolean | null | undefined,
  text: string | null | undefined,
  agentId: string | null | undefined,
): object => {
  const parameter: { [k: string]: any } = {};

  if (limit) {
    parameter.limit = limit;
  }
  if (page) {
    parameter.page = page;
  } else {
    parameter.page = 1;
  }
  if (order) {
    parameter.order = order + (orderDirection === 'desc' ? ' DESC' : '');
  } else {
    parameter.order = 'AgentId DESC';
  }
  parameter.isActive = isActive;
  if (text) {
    parameter.text = text;
  }
  if (agentId) {
    parameter.clientId = agentId;
  }

  return {
    url:  `/api/Accounts/${accountId}/agentsConfig`, 

    params: parameter,
  };
};

const GetAgentsCountConfig =  (
  accountId: number | undefined, 
  isActive: boolean | null | undefined,
  text: string | null | undefined,
  agentId: string | null | undefined,
): object => {
  const parameter: { [k: string]: any } = {};
  parameter.isActive = isActive;
  if (text) {
    parameter.text = text;
  }
  if (agentId) {
    parameter.agentId = agentId;
  }

  return { 
    url: `/api/Accounts/${accountId}/agents/count` ,
    params: parameter, 
  }; 
};

const CreateAgent =  (id: number | undefined, data: object | null):object  => {
  return { method: 'POST', url: `/api/Accounts/${id}/agent`, data: data }; 
};

const UpdateAgent =  (AccountId: number | undefined, AgentId: number | undefined, data: object | null):object  => {
  return { method: 'PUT', url: `/api/Accounts/${AccountId}/agent/${AgentId}`, data: data }; 
};

const DeleteAgent =  (AccountId: number | undefined, AgentId: number | null | undefined):object  => {
  return { method: 'DELETE', url: `/api/Accounts/${AccountId}/agent/${AgentId}` }; 
};

export {
  GetAgentsNotAccountId,
  GetAgentsConfig,
  GetAgentsCountConfig,
  UpdateAgent,
  CreateAgent,
  DeleteAgent,
};
