import './styles.css';

import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { SearchBarProps } from './index.types';
import FormComponent from '../formComponent';

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  query,
  setQuery,
  testId,
  testIdSection,
  triggerReset,
  setShowPast,
  setInactive,
  setIsActive,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState(query);

  const handleReset = () => {
    setShowPast?.(false);
    setInactive?.(false);
    setIsActive?.(true);
    setInputValue('');
    if (typeof triggerReset === 'function') {
      triggerReset();
    } else {
      setQuery('');
    }
  };

  return (
    <FormComponent
      onSubmit={() => {
        setQuery(inputValue.trim());
      }}
      className="searchForm"
      noValidate
      autoComplete="off"
    >
      <label
        className="searchTextField-label"
        data-testid={`${
          testId ? `${testId}Page` : `${testIdSection}`
        }-searchBar-input`}
      >
        <TextField
          className="searchTextField"
          InputLabelProps={{ className: 'searchInputLabel' }}
          InputProps={{
            className: 'searchInputRoot',
            inputProps: { className: 'searchInputInput' },
          }}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          label="Search"
          variant="outlined"
        />
      </label>
      <Button
        className="defaultButton"
        color="primary"
        disabled={disabled}
        variant="contained"
        type="submit"
        data-testid={`${
          testId ? `${testId}Page` : `${testIdSection}`
        }-searchBar-submitButton`}
      >
        Search
      </Button>
      <Button
        disabled={disabled}
        className="defaultButton"
        color="primary"
        onClick={handleReset}
        data-testid={`${
          testId ? `${testId}Page` : `${testIdSection}`
        }-searchBar-resetFilterButton`}
      >
        Reset filters
      </Button>
    </FormComponent>
  );
};

export default SearchBar;
