import './styles.css';
import { Button } from '@mui/material';
import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { RootState } from '../../../../store/store';
import { NewAgentModalProps } from './index.types';
import AgentModal from '../AgentModal';
import { API } from '../../../../api/api.types';
import { CreateAgent } from '../../../../api/agentsApi/agents';

const NewAgentModal: React.FunctionComponent<NewAgentModalProps> = ({ setTrigger, trigger, testId }) => {
  const [open, setOpen] = useState(false);
  const account = useAppSelector(((state: RootState) => state.user.account));
  const [error, setError] = useState<string | null>(null);
  const accountId = account?.accountId;

  const [{ loading, error: createAgentError }, refetch] =  useAxios(CreateAgent(accountId, null), { manual: true });

  const createAgent = (agentDetails: API.Agent) => {
    refetch(CreateAgent(accountId, {
      name: agentDetails.name,
      phoneNumber: agentDetails.phoneNumber,
    })).then(() => {
      setTrigger(!trigger); 
      setOpen(false);
      setError(null);
    })
      .catch(() => setError('Agent with that number already exists' || createAgentError?.message));
  };

  const onOpen = () => {
    setOpen(true);
  };
  

  const testIdSection = 'newAgentModal';

  return (
    <>
      <Button onClick={ onOpen } className="defaultButton" color="primary" variant="contained" data-testid={ `${testId}Page-${testIdSection}Button` }>
        + Add Agent
      </Button>
      <AgentModal
        open={ open }
        headerText="Create Agent"
        submitLabel='Create'
        onClose={ () => { 
          setError(null);
          setOpen(false);      
        } }
        onSubmit={ createAgent }
        apiLoading={ loading }
        apiError={ error }
        testIdSection={ testIdSection }
      />
    </>
  );
};

export default NewAgentModal;
