import './styles.css';
import React from 'react';
import {
  Dialog,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PopUpProps } from './index.types';

const PopUp: React.FC<React.PropsWithChildren<PopUpProps>> = ({
  simplePopup = false,
  open,
  onClose,
  className,
  testIdSection,
  children,
}) => {
  
  const style = className ? className : simplePopup===false ? 'dialog-popup': 'dialog-simplePopup';
  return (
    <>
      <Dialog
        disableEnforceFocus
        open={ open }
        onClose={ onClose }
        classes={ { paper:  style } }
        data-testid={ `${testIdSection}` }
      >
        { children }
        <IconButton
          aria-label="close"
          className='closeButton-popup'
          onClick={ (e) => {
            onClose(e, 'iconClick');
          } }
          data-testid={ `${testIdSection}-closeModalButton` }
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Dialog>
    </>
  );
};

export default PopUp;
