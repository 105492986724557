import { API } from '../../../../../../api/api.types';

export interface AutoInputProps {
    value: string;
  }
  
export interface RecipientState {
    countryCode: null | string | undefined;
    countryDialCode: string;
    name: string;
    phoneNumber: string;
    isAgentFound: boolean;
  }
  
export const INITIAL_RECIPIENT_STATE = {
  countryCode: null,
  name: '',
  phoneNumber: '',
  countryDialCode: '',
  isAgentFound: false,
};

export interface AgentAssignFormProps {
    onRecipientChanged?: (recipient: RecipientState) => void;
    initialRecipient: RecipientState;
    testIdSection?: string;
    edit: boolean;
    agents?: API.Agent[];
    loading?: boolean;
  }
  
