import { API } from '../../api/api.types';

export enum SECTIONS_FLAG {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export interface CreateSectionObj extends API.CreateSectionDto {
  flag?: SECTIONS_FLAG;
  imageUrl?: string | null;
  isEditable: true;
  seatmapImageUrl?:string| null
  sectionId?: number | undefined
}
export interface SectionDetailsFields {
  key: string;
  name: string;
  tmName: string;
}
export interface UpdateSectionObj extends API.UpdateSectionDto {
  sectionId?: number | undefined
  flag?: SECTIONS_FLAG;
  imageUrl?: string | null;
  isEditable: boolean;
  seatmapImageUrl?: string| null
  venueId?:number
  shownFields?:SectionDetailsFields[]
}

export type ProcessableSection =
UpdateSectionObj
  | CreateSectionObj

export interface IinitialState{
    sectionData:API.SectionRespDto[]
    sectionsChanged:boolean
  }
