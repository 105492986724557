import React from 'react';
import { IconProps } from '../index.types';

const TicketStarIcon: React.FC<IconProps> = ({
  backgroundColor,
  patternColor,
  size,
}) => (
  <svg width={ size || '120' } height={ size || '120' } viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.376953" width="112" height="112" rx="56" fill={ backgroundColor || '#007AB3' } />
    <path fill={ patternColor || 'white' } stroke={ patternColor || 'white' } d="M83.7333 50.3693V39.3101C83.7333 36.9528 81.8239 35.0435 79.4666 35.0435H32.5333C30.1759 35.0435 28.2666 36.9528 28.2666 39.3101V50.3693C30.7477 51.2504 32.5333 53.5949 32.5333 56.3768C32.5333 59.1587 30.7477 61.5032 28.2666 62.3843V73.4435C28.2666 75.8008 30.1759 77.7101 32.5333 77.7101H79.4666C81.8239 77.7101 83.7333 75.8008 83.7333 73.4435V62.3843C81.2522 61.5032 79.4666 59.1587 79.4666 56.3768C79.4666 53.5949 81.2522 51.2504 83.7333 50.3693ZM67.8442 54.1603L61.6533 58.7683L63.953 66.1347C64.2623 67.1224 63.1402 67.9373 62.2954 67.3379L55.9999 62.8749L49.7045 67.3379C48.8597 67.9373 47.7397 67.1224 48.0469 66.1347L50.3466 58.7683L44.1557 54.1603C43.3258 53.5437 43.7546 52.2253 44.7893 52.2125L52.5055 52.1229L54.9759 44.812C55.3066 43.8328 56.6933 43.8328 57.0239 44.812L59.4943 52.1229L67.2106 52.2125C68.2453 52.2232 68.6741 53.5416 67.8442 54.1603Z"/>
  </svg>
);

export { TicketStarIcon };
