import { createSlice } from '@reduxjs/toolkit';
import { TicketsState, Checkboxes } from './index.types';

const initialCheckboxesState: Checkboxes = {
  deleted: false,
  validated: false,
  unassigned: true,
  undelivered: true,
  delivered: true,
  isUpcoming: true,
};

export const initialState: TicketsState = {
  ticketsValues: [],
  searchTickets: false,
  checkboxes: initialCheckboxesState,
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTickets: (state, action) => {
      state.ticketsValues = action.payload;
    },
    setSearchTickets: (state, action) => {
      state.searchTickets = action.payload;
    },
    deleteTicketSuccess:(state, action) => {
      state.ticketsValues = state.ticketsValues.filter(ticketsValues => ticketsValues.ticketId !== action.payload);
    },
    setCheckboxes: (state, action) => {
      return {
        ...state,
        checkboxes: {
          ...state.checkboxes,
          ...action.payload
        }
        };
      },
    
    setDefaultCheckboxes: () => initialState,
  },
});

export const {
  setTickets,
  setSearchTickets,
  deleteTicketSuccess,
  setCheckboxes,
  setDefaultCheckboxes,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
