import useAxios from 'axios-hooks';
import { GetContainerConfig } from '../../api/helperApi/containerApi';
import IFileUploadResp from '../../api/interfaces';

const useUploadFile =  () => {
  const formData = new FormData();
  
  let fileUploadResp: IFileUploadResp;

  const [, refetch] = useAxios('');

  const uploadFile = async (container: string |undefined, file: File): Promise<IFileUploadResp>  => {
    formData.delete('file');
    formData.append('file', file);
    try {
      const resp = await refetch(
        GetContainerConfig(container,formData),
      );

      fileUploadResp = (await resp.data);
    } catch (error) {

      console.error(error);

      fileUploadResp = {
        createdAt: 0,
        extension: '',
        fileName: '',
        filePath: '',
        fileSize: 0,
        id: 0,
        mimeType: '',
        originalFileName: '',
        updatedAt: 0,
        accountId: 0,
      };
    }
    return fileUploadResp;
  };

  return uploadFile;
};
export default useUploadFile;
