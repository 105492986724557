import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { selectToken } from '../../../../store/authSlice';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { setCurrentLocation } from '../../../../store/breadcrumbSlice';
import {
  setUserName,
  setUserEmail,
  setAccount,
  setAccountList,
  set2FA,
  setRoles,
  setPasswordReset,
  setIsActive,
} from '../../../../store/UserSlice';
import { RootState } from '../../../../store/store';
import { GetUserDetails } from '../../../../api/loginApi/user';
import useAxios from 'axios-hooks';
import React, { Fragment, useEffect } from 'react';
import Loading from '../../loading';
import { GetConfig } from '../../../../api/configApi/config';
import { setConfig } from '../../../../store/configSlice';

const ProtectedRoute: React.FunctionComponent = () => {
  const token = useAppSelector(selectToken);
  const userName = useAppSelector((state: RootState) => state.user.userName);
  const managerId = useAppSelector((state: RootState) => state.auth.managerId);
  const is2FAEnabled = useAppSelector(
    (state: RootState) => state.user.is2FAEnabled,
  );
  const isPasswordReset = useAppSelector(
    (state: RootState) => state.user.isPasswordReset,
  );
  const dispatch = useAppDispatch();

  const features = useAppSelector(
    (state: RootState) => state.config.configLocal,
  );
  const is2FAFeatureEnabled = features.includes('2FA');

  //Manual will stop the execution of the request because we don't have access to store data on first load.
  //Refetch will allow us to make the request once the component is fully mounted so we have access to UserId in store
  const [{ loading, error }, refetch] = useAxios(GetUserDetails(managerId), {
    manual: true,
  });
  const [, refetchConfig] = useAxios(GetConfig(), { manual: true });

  const location = useLocation();

  useEffect(() => {
    refetchConfig()
      .then((res) => {
        if (res.data) {
          dispatch(setConfig(res.data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const regexLocation = () => {
      const regex = /[0-9*]/g;
      const found = location.pathname.match(regex);

      if (found && found.length > 0) {
        if (!!location.pathname.includes('event')) {
          return 'Events/Event details';
        }
        if (!!location.pathname.includes('venues')) {
          return 'Venues/Venues details';
        }
        if (!!location.pathname.includes('transfer')) {
          return 'Users/Transfer details';
        }

        if (!!location.pathname.includes('seasonticketing')) {
          return 'Season Ticketing/Season Ticketing details';
        }
      }
      if (!!location.pathname.includes('apphomeeditor')) {
        return 'Events/App Home Editor';
      }
      if (!!location.pathname.includes('managers')) {
        return 'Portal Users';
      }
      if (!!location.pathname.includes('seasonticketing/create')) {
        return 'Season Ticketing/Create';
      }
      if (!!location.pathname.includes('seasonticketing')) {
        return 'Season Ticketing';
      }
      if (!!location.pathname.includes('statistics')) {
        return 'Events/Event statistics';
      }
      if (!!location.pathname.includes('smsnotification')) {
        return 'SMS Notification';
      }
      if (!!location.pathname.includes('notifications')) {
        return 'Notifications (Last 30 days)';
      }
      if (!!location.pathname.includes('tickets')) {
        return 'Tickets';
      }
      return location.pathname;
    };

    dispatch(setCurrentLocation(regexLocation()));
    if (userName === '' && managerId && token) {
      refetch()
        .then((res) => {
          dispatch(setUserName(res.data.name));
          dispatch(setUserEmail(res.data.tixUser.email));
          dispatch(setAccount(res.data.account));
          dispatch(setAccountList(res.data.accountList));
          dispatch(setRoles(res.data.roles));
          dispatch(set2FA(res.data.is2FAEnabled));
          dispatch(setPasswordReset(res.data.isPasswordReset));
          dispatch(setIsActive(res.data.isActive));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dispatch, managerId, token, userName, refetch, location.pathname]);

  if (error) {
    //temp error page for now
    return <pre>{JSON.stringify(error.message, null, 2)}</pre>;
  }

  if (token && !isPasswordReset) {
    return (
      <Fragment>
        {!location.pathname.includes('profile') && (
          <Navigate to={`/profile`} replace state={{ location }} />
        )}
        <Outlet />
        {loading && <Loading />}
      </Fragment>
    );
  }

  if (token && (is2FAEnabled || !is2FAFeatureEnabled)) {
    return (
      <Fragment>
        <Outlet />
        {loading && <Loading />}
      </Fragment>
    );
  }
  if (token && is2FAFeatureEnabled && !is2FAEnabled) {
    return (
      <Fragment>
        {!location.pathname.includes('profile') && (
          <Navigate to={`/profile`} replace state={{ location }} />
        )}
        <Outlet />
        {loading && <Loading />}
      </Fragment>
    );
  }

  if (!token) {
    return (
      <Fragment>
        <Navigate to={`/login`} replace state={{ location }} />
        {loading && <Loading />}
      </Fragment>
    );
  }

  return null;
};

export default ProtectedRoute;
