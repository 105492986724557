import { cloneElement } from 'react';
import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';

export interface WithFeatureProps {
 children: any;
}

const WithFeature: React.FC<WithFeatureProps> = ( { children }) => {

  const features = useAppSelector(((state: RootState) => state.config.configLocal));
  if (features.includes(children.type.displayName)) {
    return cloneElement(children, { features });
  }
  return null;
};

export default WithFeature;
