import Swal from 'sweetalert2';
type Alert = {
  config: {
    apnsBundleId: string;
    apnsTokenKeyFileContent: string;
    apnsTokenKeyId: string;
    apnsTokenTeamId: string;
    gcmServerApiKey: string;
  };
  title: string;
  html: string;
  navTo?: (link: string) => void;
  link?: string;
};
export const notificationAlert = (alertMessage: Alert) => {
  const { config, title, html, navTo, link } = alertMessage;
  if (
    !config.apnsBundleId ||
    !config.apnsTokenKeyFileContent ||
    !config.apnsTokenKeyId ||
    !config.apnsTokenTeamId ||
    !config.gcmServerApiKey
  ) {
    Swal.fire({
      title,
      html,
      icon: 'error',
      confirmButtonText: 'OK',
    }).then(() => {
      if (navTo && link) {
        navTo(link);
      }
    });
    return true;
  } else {
    return false;
  }
};
