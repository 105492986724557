import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export function createSimpleComponentType<P = {}>() {
  const Component: React.FC<React.PropsWithChildren<P>> = ({
    children,
  }) => (
    <>
      { children }
    </>
  );
  return Component;
}
