import './styles.css';
import React from 'react';
import { Grid,Box } from '@mui/material';

import { createSimpleComponentType } from '../../../../core/utils/createSimpleComponentType';
import { filterChildren } from '../../../../core/utils/filterChildren';
import Loading from '../../loading';
import PopUp from '../Popup';
import { ModalProps } from './index.types';

const HEADER_ICON_SIZE = 100;

export const ModalInfoPanel = createSimpleComponentType();

export const ModalContentPanel = createSimpleComponentType();

export const ModalActionBar = createSimpleComponentType();

export const ModalTopBar = createSimpleComponentType();


const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  open,
  onClose,
  children,
  headerIcon,
  loaderState,
  testIdSection,
}) => {
  

  const headerIconProps = {
    size: HEADER_ICON_SIZE,
  };
  const styledHeaderIcon = React.cloneElement(headerIcon, headerIconProps);

  const [[info], [content], [actionBar], [topBar]] = filterChildren(
    children,
    [ModalInfoPanel, ModalContentPanel, ModalActionBar, ModalTopBar],
  );

  return (
    <>
      <PopUp
        open={ open }
        onClose={ onClose }
        testIdSection={ testIdSection }
      >
        <Grid container direction="row" className='modalContainer-modal'>
          <Grid container direction="column" item className='infoPanel-modal'>
            <Box className='headerIconPosition-modal'>
              { styledHeaderIcon }
            </Box>
            { info }
          </Grid>
          <Grid container direction="column" item xs className='contentContainer-modal'>
            <Grid item className='topBar-modal'>
              { topBar }
            </Grid>
            <Grid item xs className='contentPanel-modal'>
              { !loaderState && content }
              { loaderState && (
                <Grid className='loaderContainer-modal' container alignItems="center" justifyContent="center">
                  <Grid item xs={ 4 } className='loader-modal'>
                    <Loading />
                  </Grid>
                </Grid>
              ) }
            </Grid>
            <Grid container alignItems="center" className='actionBar-modal'>
              { actionBar }
            </Grid>
          </Grid>
        </Grid>
      </PopUp>
    </>
  );
};

export default Modal;
