import { CSSProperties } from 'react';

export const DATE_FORMAT = 'DD MMM YYYY, HH:mm';

export const TIMELINE = {
  minRowHeight: 75,
  timelineWidth: 15,
  topOffset: -15,
  rightOffset: -23,
  nodeHeight: 30,
  crossLineWidth: 2,
  qrHeight: 25,
};

export const TICKET_STATUS_STYLES: { [status: string]: CSSProperties } = {
  CREATED: {
    color: '#009900',
  },
  RESERVED: {
    color: '#ff0000',
  },
  NOTIFIED: {
    color: '#ff9d00',
  },
  UNNOTIFIED: {
    color: '#ff9d00',
  },
  CONSUMED: {
    color: '#006600',
  },
  DELIVERED: {
    color: '#008800',
  },
  DOWNLOADED: {
    color: '#008800',
  },
  DELETED: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    color: '#000000',
    opacity: 0.3,
  },
};

export const TICKET_STATUS: { [status: string]: string } = {
  CREATED: 'Created',
  RESERVED: 'Reserved',
  UNNOTIFIED: 'Not yet notified',
  NOTIFIED: 'Notified',
  NOTIFICATION_FAILURE: 'Notification failed',
  UNASSIGNED: 'At Agent',
  UNDELIVERED: 'Waiting for Delivery',
  DELIVERED: 'On Account',
  DOWNLOADED: 'Delivered',
  LASTSYNCED: 'Last updated',
  CONSUMED: 'Validated',
  CONFLICT: 'Conflict',
  DELETED: 'Deleted',
  TRANSFERRED: 'Transferred',
  NULL: '',
};

export const LOG_STATUS: { [logEvent: string]: string } = {
  CREATE: 'Created',
  DELIVER: 'On account',
  NOTIFY: 'Notified',
  DOWNLOAD: 'Downloaded',
  DELETE: 'Deleted',
  AGENT_DELETE: 'Agent Deleted',
  UPDATE: 'Updated',
  RELAY: 'Reassigned',
  RECALL: 'Recalled',
  ASSIGN: 'Assigned',
  TRANSFER: 'Transferred',
  QRCHANGE: 'QR changed',
  CONSUME: 'Validated',
  SYNC: 'Synced',
  SCREENSHOT: 'Screenshot',
};

export const LOG_REASON: {
  [logEvent: string]: { [logReason: string]: string };
} = {
  CREATE: {
    AGENT: 'For agent',
  },
  DELETE: {
    CANCEL_TRANSACTION: 'Transaction cancelled',
    MANAGER: 'By admin',
    SYSTEM: 'Cancelled by integration',
    CLIENT: 'From app',
  },
  RELAY: {
    AGENT: 'To agent',
  },
  RECALL: {
    AGENT: 'By agent',
  },
  ASSIGN: {
    USER: 'To user',
    OWNER_REGISTERED: 'Owner registered',
  },
  TRANSFER: {
    SIMPLE: 'From app',
    AGENT: 'By admin',
    'TRANSFER CANCELED': 'CANCELED',
    'TRANSFER INITIATED': 'INITIATED',
    REASSIGN_FROM_HOLDER_TO_OWNER: 'Owner registered',
    REASSIGN_OWNER_REGISTERED: 'Owner registered',
    REASSIGN_HOLDER_REGISTERED: 'Holder registered',
  },
  QRCHANGE: {
    USER_DEACTIVATED: 'User deactivated',
  },
};

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const emptySelection = 'emptySelection';
