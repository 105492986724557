import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  ProcessableTicketType,
  IupdateTicketType,
  TICKETTYPE_FLAG,
} from './index.types';
import _ from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import * as R from 'ramda';
import moment from 'moment';
import {
  TransferPolicyValues,
  NotificationType,
  AlertPolicy,
  ActivationPolicy,
  GateStaffPolicyValues,
  EventRespDtoExtended,
  SetToUpdatePayload,
} from './index.types';

const HRSINMS = 1000 * 60 * 60;
const DAYSINMS = HRSINMS * 24;
const ticketActivationTimeOffset = 1;
const ticketNotificationTimeOffset = 24;
const showStartTimeOffset = 1;
const showEndTimeOffset = 2;

function roundToHour(timeInMillisec: number) {
  const date = new Date(timeInMillisec);
  return Math.ceil(date.getTime() / HRSINMS) * HRSINMS;
}

function roundDownToHour(timeInMillesec: number) {
  const date = new Date(timeInMillesec);
  return Math.floor(date.getTime() / HRSINMS) * HRSINMS;
}

function generateRandomCode() {
  return Math.floor(1000 + Math.random() * 9000);
}

const INITIAL_TRANSFER_POLICY: TransferPolicyValues = {
  transferPolicy: {
    creationMethod: 'looseNaming',
    priceGain: false,
    moveAsIs: false,
    simpleTransfer: false,
    antiTouting: false,
    agentTransfer: false,
    refund: false,
    blindResale: false,
    directResale: false,
  },
  priceGainMax: 5,
};

const INITIAL_NOTIFICATION_TYPE: NotificationType = {
  sendBefore:
    moment().unix() * 1000 -
    (ticketActivationTimeOffset + ticketNotificationTimeOffset) * HRSINMS,
  type: 'OFFSET',
};

const INITIAL_ALERT_POLICY: AlertPolicy = {
  eventAlert: false,
  alertText: '',
};

const INITIAL_ACTIVATION_POLICY: ActivationPolicy = {
  gateOpen: true,
  gateImage: true,
  publicActivationCode: true,
};

const INITIAL_GATE_STAFF_POLICY: GateStaffPolicyValues = {
  gateStaffPolicy: {
    activationCode: true,
    invalidationCode: true,
    randomSpotChecks: true,
    targetedSpotChecks: false,
  },
  invalidationCode: generateRandomCode(),
  password: '',
  spotCheckPercentage: 5,
};

const INITIAL_EVENT_VALUES: EventRespDtoExtended = {
  performance: {
    mediaUrls: [],
  },
  ticketTypes: [],
  onaccountTickets: 0,
  downloadedTickets: 0,
  validatedTickets: 0,
  unnotifiedTicketCount: 0,
  issuedTickets: 0,
  alertTime: roundToHour(
    moment().unix() * 1000 - ticketActivationTimeOffset * HRSINMS,
  ),
  alertPolicy: INITIAL_ALERT_POLICY,
  venueOpenTime: roundToHour(moment().unix() * 1000),
  ticketActivationTime: roundToHour(
    moment().unix() * 1000 - ticketActivationTimeOffset * HRSINMS,
  ),
  radius: 500,
  eventStartTime: roundToHour(
    moment().unix() * 1000 + showStartTimeOffset * HRSINMS,
  ),
  eventFinishTime: roundToHour(
    moment().unix() * 1000 + showEndTimeOffset * HRSINMS,
  ),
  securityType: 0,
  transferPolicy: INITIAL_TRANSFER_POLICY.transferPolicy,
  priceGainMax: INITIAL_TRANSFER_POLICY.priceGainMax,
  activationPolicy: INITIAL_ACTIVATION_POLICY,
  gateStaffPolicy: INITIAL_GATE_STAFF_POLICY.gateStaffPolicy,
  invalidationCode: INITIAL_GATE_STAFF_POLICY.invalidationCode,
  spotCheckPercentage: INITIAL_GATE_STAFF_POLICY.spotCheckPercentage,
  notificationType: INITIAL_NOTIFICATION_TYPE,
  password: INITIAL_GATE_STAFF_POLICY.password,
  performanceId: 0,
  integrations: [],
  linkedEvents: [],
  isPeriod: false,
};

export const initialState = {
  eventLocal: INITIAL_EVENT_VALUES,
  eventOrigin: INITIAL_EVENT_VALUES,
  changed: false,
  reloadEvents: false,
  saveDisabled: false,
  errors: {},
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    resetEvent: (state) => {
      return (state = { ...initialState, reloadEvents: state.reloadEvents });
    },
    setOriginEvent: (state, action: PayloadAction<EventRespDtoExtended>) => {
      state.eventOrigin = action.payload;
    },
    setEvent: (state, action: PayloadAction<EventRespDtoExtended>) => {
      //needs to use spread operator in order to create new object so rerender of dependent components is run

      state.eventLocal = {
        ...action.payload,
      };
    },
    updateEvent: (
      state,
      action: PayloadAction<Partial<EventRespDtoExtended>>,
    ) => {
      state.eventLocal = { ...state.eventLocal, ...action.payload };
    },
    setReloadEvents: (state, action) => {
      state.reloadEvents = action.payload;
    },
    setSaveDisable: (state, action) => {
      state.saveDisabled = action.payload;
    },
    updateTicketFlag: (state, action: PayloadAction<IupdateTicketType>) => {
      state.eventLocal = {
        ...state.eventLocal,
        ticketTypes: state.eventLocal?.ticketTypes?.map((ticketType) =>
          ticketType.ticketTypeId === action.payload.id
            ? { ...ticketType, flag: action.payload.flagValue }
            : ticketType,
        ),
      };
    },
    updateTicketTypeId: (state, action: PayloadAction<IupdateTicketType>) => {
      state.eventLocal = {
        ...state.eventLocal,
        ticketTypes: state.eventLocal?.ticketTypes?.map((ticketType) =>
          ticketType.ticketTypeId === action.payload.id
            ? {
                ...ticketType,
                ticketTypeId:
                  action.payload.ticketTypeId || ticketType.ticketTypeId,
                flag: action.payload.flagValue,
              }
            : ticketType,
        ),
      };
    },
    updateTicket: (state, action: PayloadAction<SetToUpdatePayload>) => {
      state.eventLocal = {
        ...state.eventLocal,
        ticketTypes: state.eventLocal?.ticketTypes?.map((ticketType) =>
          ticketType.ticketTypeId === action.payload.ticketTypeId
            ? { ...ticketType, ...action.payload }
            : ticketType,
        ),
      };
    },
    createTicket: (state, action: PayloadAction<ProcessableTicketType>) => {
      state.eventLocal = {
        ...state.eventLocal,
        ticketTypes: [...(state.eventLocal?.ticketTypes || []), action.payload],
      };
    },
    setTransferPolicy: (state, action) => {
      state.eventLocal = {
        ...state.eventLocal,
        transferPolicy: action.payload.transferPolicy,
        priceGainMax: action.payload.priceGainMax,
      } as EventRespDtoExtended;
    },
    setVenueOpenTime: (state, action) => {
      const { venueOpenTime, alertTime, ticketActivationTime } =
        state.eventLocal;
      if (venueOpenTime && alertTime && ticketActivationTime) {
        const msDiff = action.payload - venueOpenTime;
        state.eventLocal = {
          ...state.eventLocal,
          venueOpenTime: action.payload,
          alertTime: alertTime + msDiff,
          ticketActivationTime: ticketActivationTime + msDiff,
          notificationType: {
            ...state.eventLocal.notificationType,
            sendBefore: state.eventLocal.notificationType.sendBefore + msDiff,
          },
        };
      }
    },
    setActivationPolicy: (state, action) => {
      state.eventLocal = {
        ...state.eventLocal,
        activationPolicy: action.payload,
      } as EventRespDtoExtended;
    },
    setGateStaffPolicy: (state, action) => {
      state.eventLocal = {
        ...state.eventLocal,
        gateStaffPolicy: action.payload.gateStaffPolicy,
        spotCheckPercentage: action.payload.spotCheckPercentage,
        invalidationCode: action.payload.invalidationCode,
      } as EventRespDtoExtended;
    },
    setTicketActivationTime: (state, action) => {
      if (!action.payload && action.payload !== 0) {
        state.eventLocal = {
          ...state.eventLocal,
          ticketActivationTime: null,
        };
      } else if (state.eventLocal.venueOpenTime) {
        const sendBeforeLocalMoment = moment(
          state.eventLocal.notificationType.sendBefore,
        );
        const ticketActivationTimeLocalMoment = moment(
          state.eventLocal.ticketActivationTime,
        );
        const sendBeforeHours = ticketActivationTimeLocalMoment.diff(
          sendBeforeLocalMoment,
          'hours',
        );
        state.eventLocal = {
          ...state.eventLocal,
          notificationType: {
            ...state.eventLocal.notificationType,
            sendBefore:
              state.eventLocal.venueOpenTime -
              action.payload * HRSINMS -
              sendBeforeHours * HRSINMS,
          },
          ticketActivationTime:
            state.eventLocal.venueOpenTime -
            (action.payload.menuOption === 'HOURS'
              ? action.payload.value * HRSINMS
              : action.payload.value * DAYSINMS),
        };
      }
    },
    setImmediateTicketActivation: (state, action) => {
      state.eventLocal = {
        ...state.eventLocal,
        ticketActivationTime: roundDownToHour(action.payload.timestamp),
        notificationType: action.payload.notificationType,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEvent,
  updateTicketFlag,
  createTicket,
  updateTicket,
  updateTicketTypeId,
  resetEvent,
  setOriginEvent,
  setVenueOpenTime,
  setTransferPolicy,
  setTicketActivationTime,
  setActivationPolicy,
  setGateStaffPolicy,
  setReloadEvents,
  setSaveDisable,
  updateEvent,
  setImmediateTicketActivation,
} = eventSlice.actions;

export default eventSlice.reducer;

export const getTicketTypeById = (id: number) =>
  createSelector(
    (state: RootState) => state.event.eventLocal?.ticketTypes,
    (allTicketTypes) => {
      if (allTicketTypes) {
        return allTicketTypes.find((ticketTypes) => {
          return ticketTypes.ticketTypeId === id;
        });
      } else {
        return null;
      }
    },
  );

export const getTicketTypesCheck = () =>
  createSelector(
    (state: RootState) => state.event.eventLocal?.ticketTypes,
    (allTicketTypes) => {
      const data = [
        ...allTicketTypes.filter(
          (item) => item.flag !== TICKETTYPE_FLAG.DELETE,
        ),
      ];
      if (data.length > 0) {
        return true;
      }
      return false;
    },
  );

export const getTicketActivationTimeHrs = () =>
  createSelector(
    (state: RootState) => state.event.eventLocal,
    (eventLocal) => {
      if (
        eventLocal.ticketActivationTime &&
        eventLocal.notificationType === 'IMMEDIATE'
      ) {
        return eventLocal.ticketActivationTime;
      } else if (eventLocal.ticketActivationTime && eventLocal.venueOpenTime) {
        const { ticketActivationTime, venueOpenTime } = eventLocal;
        const ticketActivationMoment = moment(ticketActivationTime);
        const venueOpenTimeMoment = moment(venueOpenTime);
        const activationTimeHrs = Math.abs(
          venueOpenTimeMoment.diff(ticketActivationMoment, 'hours'),
        );
        return activationTimeHrs;
      } else if (!eventLocal.ticketActivationTime) {
        return '';
      }
      return 1;
    },
  );

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  _.isEqual,
);

export const changed = () =>
  createDeepEqualSelector(
    (state: RootState) => state.event.eventLocal,
    (state: RootState) => state.event.eventOrigin,
    (localEvent, originalEvent) => {
      //Todo check only core event changed and then have differert checks for tickettype and performace
      const o = R.omit(['performance', 'ticketTypes'], originalEvent);
      const l = R.omit(['performance', 'ticketTypes'], localEvent);

      const eventChanged = !_.isEqual(o, l);
      const performanceChanged = !_.isEqual(
        originalEvent?.performance,
        localEvent?.performance,
      );
      const ticketTypeChanged = !_.isEqual(
        originalEvent?.ticketTypes,
        localEvent?.ticketTypes,
      );

      return {
        eventChanged,
        performanceChanged,
        ticketTypeChanged,
      };
    },
  );
