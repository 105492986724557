import React from 'react';
import { IconProps } from '../index.types';

export const AgentIcon: React.FC<IconProps> = ({
  backgroundColor,
  size,
}) => (
  <svg width={ size || '120' } height={ size || '120' } viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={ backgroundColor || '#000' } d="M12 0.501953C7.758 0.501953 6 3.22295 6 6.50195C6 7.60595 6.52734 8.71484 6.52734 8.71484C6.31534 8.83684 5.96669 9.22411 6.05469 9.91211C6.21869 11.1951 6.77491 11.5218 7.12891 11.5488C7.26391 12.7458 8.55 14.277 9 14.5V16.5C8.99781 16.5066 8.99249 16.511 8.99023 16.5176C8.65954 16.5586 8.36449 16.7538 8.21484 17.0566L7.90234 17.6855C5.43429 19.0694 0 18.7263 0 24.5H10.3047L11.5 20.584L10.3613 18.5H11.9746H13.6387L12.5 20.584L13.6953 24.5H24C24 18.7263 18.5657 19.0694 16.0977 17.6855L15.7852 17.0566C15.6355 16.7538 15.3405 16.5586 15.0098 16.5176C15.0075 16.511 15.0022 16.5066 15 16.5V14.5C15.45 14.277 16.7361 12.7458 16.8711 11.5488C17.2251 11.5218 17.7813 11.1951 17.9453 9.91211C18.0333 9.22311 17.6847 8.83684 17.4727 8.71484C17.4727 8.71484 18 7.71295 18 6.50195C18 4.07395 17.047 2.00195 15 2.00195C15 2.00195 14.289 0.501953 12 0.501953Z" />
  </svg>

);
