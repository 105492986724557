import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TixImage {
  filePath: string;
}

type Account = {
  accountId?: number;
  companyLogo?: TixImage;
  tmActive?: boolean;
  accountName?: string;
  integrations?: {
    stay22: { affiliateId: string; activated: boolean; baseUrl: string };
    monterosa: { host: string; activated: string; projectId: string };
  };
  transferInvitationContent?: string;
  invitationContent?: string;
  ticketSentInvitationContent?: string;
} | null;

interface AccountList {
  accountId: number;
  accountName: string;
  default: boolean;
}
export interface UserState {
  userName: string;
  userEmail: string;
  account: Account;
  accountList: AccountList[];
  is2FAEnabled: boolean;
  roles: string[];
  isActive: boolean;
  isPasswordReset: boolean;
}

const initialState: UserState = {
  userName: '',
  userEmail: '',
  account: null,
  accountList: [],
  is2FAEnabled: true,
  roles: [],
  isActive: false,
  isPasswordReset: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    setAccount: (state, action: PayloadAction<object>) => {
      state.account = action.payload;
    },
    setAccountList: (state, action: PayloadAction<AccountList[]>) => {
      state.accountList = action.payload;
    },
    setRoles: (state, action: PayloadAction<string[]>) => {
      state.roles = action.payload;
    },
    set2FA: (state, action: PayloadAction<boolean>) => {
      state.is2FAEnabled = action.payload;
    },
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setPasswordReset: (state, action: PayloadAction<boolean>) => {
      state.isPasswordReset = action.payload;
    },
    userReset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserEmail,
  setUserName,
  userReset,
  setAccount,
  setAccountList,
  set2FA,
  setRoles,
  setPasswordReset,
  setIsActive,
} = userSlice.actions;

export default userSlice.reducer;
