import { cloneElement } from 'react';
import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { Grid, Typography } from '@mui/material';

export interface WithRoleProps {
  roles: string[];
  errorScreen?: boolean;
  children: any;
}

const WithRole: React.FC<WithRoleProps> = ({
  roles,
  children,
  errorScreen = false,
}) => {
  const userRoles =
    useAppSelector((state: RootState) => state.user.roles) || [];

  const intersection = userRoles.filter((value) => roles.includes(value));

  if (intersection.length || userRoles.includes('admin')) {
    return cloneElement(children);
  }
  if (errorScreen) {
    return (
      <Grid container direction="column" alignItems="center" marginTop={'15%'}>
        <Typography marginBottom={'20px'} fontSize={'30px'}>
          <strong>Access Restricted</strong>
        </Typography>
        <Typography alignContent={'space-evenly'}>
          You don't have access to this page. If you require{' '}
        </Typography>
        <Typography alignContent={'space-evenly'}>
          permissions, please reach out to your account admin.
        </Typography>
      </Grid>
    );
  }
  return null;
};

export default WithRole;
