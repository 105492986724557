import React, { useCallback, useEffect, useState } from 'react';
import { RecipientState, AgentAssignFormProps } from './index.types';
import { API } from '../../../../../../api/api.types';
import { Grid, TextField, Typography } from '@mui/material';
import { AgentIcon } from '../../../../../core/popup/popupIcons';
import { countries } from '../../../../../core/Phone/CountryCodes';
import { getNationalNumber, isValidPhoneNumber, parsePhoneNumber } from '../../../../../core/Phone/Phone';
import { CountryAutocomplete } from '../../../../../tickets/ticketModal/components/countryAutocomplete';
import useAxios from 'axios-hooks';
import { useAppSelector } from '../../../../../../store/hooks';
import { RootState } from '../../../../../../store/store';
import { GetAgentsNotAccountId } from '../../../../../../api/agentsApi/agents';

export const AgentAssignForm: React.FunctionComponent<AgentAssignFormProps> = ({
  onRecipientChanged,
  initialRecipient,
  testIdSection,
  edit,
}) => {

  const account = useAppSelector(((state: RootState) => state.user.account));
  const accountId = account?.accountId;
  const [{ data: agents, loading }, refetchAgents] =  useAxios(GetAgentsNotAccountId(accountId, null), { manual: !accountId  });

  const [
    recipient,
    setRecipient,
  ] = useState<RecipientState>(initialRecipient);

  const [
    allPhoneNumbers,
    setAllPhoneNumbers,
  ] = useState<(RecipientState | API.Agent)[]>([]);
  

  useEffect(() => {
    if (typeof onRecipientChanged === 'function') {
      onRecipientChanged(recipient);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipient]);

  useEffect(() => {
    const phoneNumberCombined =  getNationalNumber(recipient.countryDialCode + recipient.phoneNumber, recipient.countryDialCode);
    if(isValidPhoneNumber(phoneNumberCombined)){
    refetchAgents(GetAgentsNotAccountId(accountId, phoneNumberCombined));
  }
},
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [recipient.phoneNumber],
  );

  useEffect(() => {
    const phoneNumberCombined =  getNationalNumber(recipient.countryDialCode + recipient.phoneNumber, recipient.countryDialCode);
    if(agents){
      setAllPhoneNumbers([
        ...agents?.filter(
          (a: any) => a.phoneNumber.includes(phoneNumberCombined)
          || a.name.toLowerCase().includes(recipient.phoneNumber.toLowerCase()),
        ),
      ]);};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipient, agents]);
  
  const parseCountry = (number: string) => {
    const parserResult = parsePhoneNumber(number);
    let countryCode: string | null = null;
    if (parserResult && parserResult.countryCode) {
      countryCode = parserResult.countryCode;
    }
    return countryCode;
  };

  const setAgentRecipient = useCallback(
    (
      newRecipient: RecipientState | API.AgentInfoRespDto | API.Agent,
    ) => {
      if (typeof newRecipient !== 'string') { 
        if ((newRecipient as API.AgentInfoRespDto)?.name) {
          const optionHolder = newRecipient as API.AgentInfoRespDto;
          if(edit){
            setRecipient({
              ...recipient,
              phoneNumber: optionHolder.phoneNumber,
              countryDialCode:
                countries.find(
                  (country) => country.code === parseCountry(
                    optionHolder.phoneNumber || '',
                  ),
                )?.dialCode || '',
              countryCode: parseCountry(optionHolder.phoneNumber || ''),
              isAgentFound: true,
            });

          }else{
            setRecipient({
              ...recipient,
              phoneNumber: optionHolder.phoneNumber,
              name: optionHolder.name,
              countryDialCode:
                countries.find(
                  (country) => country.code === parseCountry(
                    optionHolder.phoneNumber || '',
                  ),
                )?.dialCode || '',
              countryCode: parseCountry(optionHolder.phoneNumber || ''),
              isAgentFound: true,
            });
          }
        }
      }
    },
    [recipient, setRecipient, edit],
  );

  useEffect(() => {
    if (!loading) {
      const { countryDialCode, phoneNumber } = recipient;
      const combinedPhoneNumber = getNationalNumber(countryDialCode + phoneNumber, countryDialCode);
      const numberMatches = allPhoneNumbers
        .filter((rec) => rec.phoneNumber?.startsWith(combinedPhoneNumber));
      if (numberMatches.length === 1) {
        if (
          phoneNumber !== ''
          && phoneNumber !== numberMatches[0].phoneNumber
          && combinedPhoneNumber === numberMatches[0].phoneNumber
        ) {
          setAgentRecipient(numberMatches[0]);
        }
      } else if (
        numberMatches.length === 2
        && combinedPhoneNumber === numberMatches[0].phoneNumber
        && phoneNumber !== numberMatches[0].phoneNumber) {
        if (numberMatches[0].phoneNumber === numberMatches[1].phoneNumber) {
          setAgentRecipient(numberMatches[0]);
        }
      } 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipient, allPhoneNumbers, loading]);
  
  const handleFreeSoloChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const cleanedValue = Number.isNaN(parseInt(e.target.value, 10).valueOf())
        ? e.target.value
        : e.target.value.trim().replace(/ /g, '');
      setRecipient({
        ...recipient,
        phoneNumber: cleanedValue,
        isAgentFound: false,
      });
      
      const countryCode = parseCountry(cleanedValue);
      if (countryCode) {
        setRecipient({
          ...recipient,
          phoneNumber: parsePhoneNumber(cleanedValue).nationalNumber!,
          countryCode,
          countryDialCode: countries.find((country) => country.code === countryCode)?.dialCode || '',
        });
      }
    }, [recipient],
  );

  return (
    <>
      <Grid item xs={ 12 }>
        <Typography color="primary" variant="h5">
           Agent Details
        </Typography>
      </Grid>
      <Grid item xs={ 3 }>
        <CountryAutocomplete
          value={ recipient.countryCode! || parsePhoneNumber(recipient.phoneNumber).countryCode! }
          onCountrySelected={ (country: any) => {
            if (country !== null && recipient.countryCode !== country.code) {
              setRecipient({
                ...recipient,
                countryCode: country.code,
                countryDialCode: country.dialCode,
              });
            }
          } }
          testIdSection={ testIdSection }
        />
      </Grid>
      <Grid item xs={ 6 }>
        <TextField
          fullWidth
          value={ parsePhoneNumber(recipient.phoneNumber).nationalNumber! }
          label="Number search..."
          size="small"
          onChange={ handleFreeSoloChange }
          data-testid={ `${testIdSection}-recipient-search-dropdown` }
        />
      </Grid>
      { recipient.isAgentFound === true && (
        <Grid container className="nameHolder">
          <Grid item xs={ 3 } />
          <Grid item xs={ 6 }>
            <Grid container alignItems="center">
              <Grid item xs={ 2 }>
                <AgentIcon size={ 32 } />
              </Grid>
              <Grid item xs={ 8 }>
                <Typography color="primary" variant="body1" align="left"  data-testid={ `${testIdSection}-agent-found` }>
                  { edit ? 'Number Already in Use' : recipient.name }
                </Typography>
              </Grid>
            </Grid>
             
          </Grid>
        </Grid>
      ) }
      { !recipient.isAgentFound
       && !loading
       && (
         <>
           <Grid item xs={ 9 }>
             <TextField
               size="small"
               value={ recipient.name }
               onChange={ (e) => setRecipient({ ...recipient, name: e.target.value }) }
               placeholder="Name"
               fullWidth
               id={ Math.random().toString() }
               label="Name"
               data-testid={ `${testIdSection}-recipient-Name-input` }
             />
           </Grid>
         </>
       ) }
    </>
  );
};
