import './styles.css';

import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import ButtonProps from './types';
function containsNumbers(str: string) {
  return str.includes('/statistics/') ? /\d/.test(str) : false;
}

const Button: React.FunctionComponent<ButtonProps> = ({ name, linkTo, icon }) => {
  const disable = containsNumbers(window.location.pathname);
  return ( 
    <Link style={{ pointerEvents: disable && linkTo === '/statistics' ? 'none' : 'auto' }}  to={ linkTo } >
      <div className='button'> 
        <div className="leftGrid"></div>      
        <div className='rightGrid'>
          <div className="iconBox">
            <div className={ `fa fa-lg fa-fw  ${ icon }` }></div>
          </div>
          <div className='nameBox' role="heading" aria-level={ 7 }>{ name }</div>   
        </div>
      </div>   
    </Link>    
  );
};

export default Button;
