


const loginConfig = (email: string, password: string, verificationCode: string): object => {
  
  return {
    method : 'POST',
    url: `/api/Managers/login`,
    data: {
      email,
      password,
      verificationCode,
    },
  };
  
};

const getTokenExpirationTime = (created:string, ttl:number) => {
  return  new Date(created).getTime() + ttl * 1000;
};
const setLocalStorage = (token:string, userId:string, managerId:string, tokenExpirationTime:string) => {
  localStorage.setItem('token', token);
  localStorage.setItem('currentUserId', userId);
  localStorage.setItem('currentManagerId', managerId);
  localStorage.setItem('tokenExpirationTime',  tokenExpirationTime);
};
const logout = () => {

  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpirationTime');
  localStorage.removeItem('currentUserId');
  localStorage.removeItem('currentManagerId');

  //Will be implements when using new backend to log out the backend

  // fetch(`${BASE_URL}/api/logout`, {
  //   method: 'POST',
  //   headers: {
  //     'content-type': 'application/json',
  //   },
  //   body: token,
  // })
  //   .then(res => {
  //     console.log(res.statusText);
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('tokenExpirationTimeMs');
  //   })
  //   .catch(err => console.log(err));
};



const GetUserDetails =  ( id : number | null):object  => {
  return { url:`/api/Managers/${id}` };
};

export {
  loginConfig,
  logout,
  GetUserDetails,
  getTokenExpirationTime,
  setLocalStorage,
};
