import React from 'react';
import { IconProps } from '../index.types';

const SaveIcon: React.FC<IconProps> = ({
  backgroundColor = 'none',
  patternColor = 'white',
  size = 16,
}) => (
  <svg width={ size } height={ size } viewBox="0 0 16 16" fill={ backgroundColor } xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99984 0.666016C1.26317 0.666016 0.666504 1.26268 0.666504 1.99935V13.9993C0.666504 14.736 1.26317 15.3327 1.99984 15.3327H13.9998C14.7365 15.3327 15.3332 14.736 15.3332 13.9993V3.33268L12.6665 0.666016H11.3332V4.66602C11.3332 5.03402 11.0345 5.33268 10.6665 5.33268H4.6665C4.2985 5.33268 3.99984 5.03402 3.99984 4.66602V0.666016H1.99984ZM8.6665 0.666016V3.99935H9.99984V0.666016H8.6665ZM4.6665 8.66602H11.3332C12.0698 8.66602 12.6665 9.26268 12.6665 9.99935V13.9993H3.33317V9.99935C3.33317 9.26268 3.92984 8.66602 4.6665 8.66602Z" fill={ patternColor } />
  </svg>
);

export { SaveIcon };
