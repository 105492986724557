import { Button, Grid } from '@mui/material';
import TicketValidationTable from '../ticketValidationTable';
import { PreImportTicketExtended } from './types';
import { useNavigate } from 'react-router-dom';

export const ProcessValidation: React.FC<{validationData: PreImportTicketExtended[]}> = ({ validationData }) => {
  const testId = 'ticket-import-validation';
  const navigate = useNavigate();
  
  return  <Grid container justifyContent={ 'center' } mt={ 6 }>
    <Grid item xs={ 12 } lg={ 12 }>
      <TicketValidationTable
        validationData={ validationData }
        testId={ testId }
      /> 
    </Grid>
   
    <Grid item xs={ 12 } lg={ 3 }>  
      <Button onClick={ () =>  {
        navigate('/tickets/import');
      } }  
      className='textButton'
      color="primary" 
      fullWidth={ true }
      variant="contained" 
      data-testid={ `${testId}Page-${testId}Button` }>
            Back to the import list
      </Button>
    </Grid>
  </Grid>;
};
