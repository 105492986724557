import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import breadcrumbReducer from './breadcrumbSlice';
import userReducer from './UserSlice';
import eventReducer from './eventSlice';
import venueReducer from './venueSlice';
import sectionReducers from './sectionSlice';
import configReducer from './configSlice';
import ticketsReducer from './ticketSlice';
import agentReducer from './agentSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    breadcrumb: breadcrumbReducer,
    user:userReducer,
    event:eventReducer,
    venue:venueReducer,
    section:sectionReducers,
    config:configReducer,
    tickets: ticketsReducer,
    agents: agentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
