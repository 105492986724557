import '../Button/styles.css';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../Button';

const SeasonButtonLink: React.FunctionComponent = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className={ pathname === '/seasonticketing' ? 'active' : '' } data-testid={ `sideNavMenu-pageName-seasonLink` }>
      <Button name='Season Ticketing' linkTo='/seasonticketing' icon='fa-calendar-plus-o' />
    </div>
  );
};

export default SeasonButtonLink;
SeasonButtonLink.displayName = 'SEASON';


