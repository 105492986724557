import './styles.css';

import { TableCell, TableHead, TableRow, TableSortLabel, IconButton } from '@mui/material';
import React, { Fragment } from 'react';
import { GridHeadProps } from './index.types';

const GridHead:React.FunctionComponent<React.PropsWithChildren<GridHeadProps>> = ({ list, orderColumn, orderDirection, changeOrder, testId, openModal }) => {      
  return (
    <Fragment>
      <TableHead >
        <TableRow>
          { list.map((headCell: any, index: number) => (
            <TableCell 
              className={ headCell.class?headCell.class: '' }
              sortDirection={ orderColumn === headCell.id ? orderDirection : false }   
              key={ headCell.id }
              data-testid={ `${testId}Table-columnHeader-${headCell.id}` } 
            > 
              { headCell.sort && <TableSortLabel
                className= { headCell.sort?'blueHeader':'header' }
                active={ orderColumn === headCell.id }
                direction={ (orderColumn === headCell.id && orderDirection === 'desc') ?'desc' : 'asc' }
                onClick={ ()=> {changeOrder(headCell.id); } }
              >
                { headCell.label }
              </TableSortLabel> }
              { !headCell.sort && <>
                { headCell.label }
                <span>
                  <IconButton aria-label="information" color="primary" onClick={ openModal } className="iconButton-gridHead" data-testid={ `${testId}Table-columnHeader-${headCell.id}-infoButton` }>
                    { headCell.icon }
                  </IconButton>
                </span>
              </>
              }
            </TableCell>
          )) }
        </TableRow>
      </TableHead>
    </Fragment>
  );
};

export default GridHead;
