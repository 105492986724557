const GetSendNotificationsConfig = (accountId: number | undefined,  data: object):object =>{
  return { method : 'POST', url: `/api/Accounts/${accountId}/notifications/sendnotifications`, data };
};

const GetNotificationsSettingsConfig = (accountId: number | undefined):object =>{
  return { url: `/api/Accounts/${accountId}/notifications/config` };
};

const GetNotificationsConfig = (accountId: number | undefined, limit: number | null, 
  page: number | null ):object =>{
  const parameter:{[k: string]: any} = {};
  if (limit) {parameter.limit = limit;}
  if (page) {parameter.page = page;} else {parameter.page = 1;}
  return { method : 'GET', url: `/api/Accounts/${accountId}/notifications` , params: parameter };
};

const GetNotificationsCountConfig = (accountId: number | undefined):object =>{

  return { method : 'GET', url: `/api/Accounts/${accountId}/notifications/count`  };
};

const GetNotificationsErrorsConfig = (accountId: number | undefined, notificationsId: number | undefined):object =>{

  return { method : 'GET', url: `/api/Accounts/${accountId}/notifications/${notificationsId}/errors`  };
};

const GetConsents = ( accountId: number | undefined ) => {
  return{ url: `/api/Accounts/${accountId}/consents` };
}; 

export {
  GetSendNotificationsConfig,
  GetNotificationsConfig,
  GetNotificationsCountConfig,
  GetNotificationsErrorsConfig,
  GetNotificationsSettingsConfig,
  GetConsents,
};
