import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Fragment, useState } from 'react';
import AgentModal from '../AgentModal';
import { RootState } from '../../../../store/store';
import { useAppSelector } from '../../../../store/hooks';
import useAxios from 'axios-hooks';
import { UpdateAgent } from '../../../../api/agentsApi/agents';
import { EditAgentModalProps } from './index.types';
import { API } from '../../../../api/api.types';



const EditAgentModal: React.FunctionComponent<EditAgentModalProps> = ({ agentData, handleSetTrigger, testId, index }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const account = useAppSelector(((state: RootState) => state.user.account));
  const accountId = account?.accountId;
  const agentId = agentData?.agentId;  
  const [{ loading, error: updateAgentError }, refetch] =  useAxios(UpdateAgent(accountId, undefined, null), { manual: true });
 
  const updateAgent = (agentDetails: API.Agent) => {
    
    refetch(UpdateAgent(accountId, agentId, agentDetails))
      .then(() => {
        handleSetTrigger();
        setOpen(false);})
      .catch(() => setError('Agent with that number already exists' || updateAgentError?.message));
  };
  
  const onOpen = () => {
    setOpen(true);
  };
  
  return (
    <Fragment>
      <IconButton className="editButton" aria-label='edit' onClick={ onOpen } data-testid={ `${testId}Table-editAgentModalButton-${index}` } >
        <Edit aria-label='edit'/>
      </IconButton>
      <AgentModal
        open={ open }
        headerText="Edit Agent"
        submitLabel="Update"
        onClose={ () => { 
          setError(null);
          setOpen(false); 
        } }
        onSubmit={updateAgent}
        apiLoading={ loading }
        apiError={ error }
        agentData={ agentData }
        testIdSection="editAgentModal"
      />
    </Fragment>
  );
};
  
export default EditAgentModal;
