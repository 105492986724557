import React from 'react';
import { IconProps } from '../index.types';

export const CheckedIcon: React.FC<IconProps> = ({
  backgroundColor,
  patternColor,
  size,
}) => (
  <svg width={ size || '120' } height={ size || '120' } viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z" fill={ backgroundColor || '#007AB3' } />
    <path d="M27.5 59.5L49 81L92 38" stroke={ patternColor || 'white' } strokeWidth="4" strokeLinejoin="round" />
  </svg>
);
