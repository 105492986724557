import React from 'react';
import './styles.css';
import Footer from '../Footer';
import { Outlet } from 'react-router-dom';
import logo from '../../../../assets/images/tixserve.png';

const SplashscreenLayout: React.FunctionComponent = () => {
  return (
    <div className="container-splashScreen">
      <div className="splash-layout-splashScreen">
        <img className="logo-name-splashScreen" src={logo} alt="Logo"></img>
        <main>
          <Outlet />
        </main>
        <footer className="footer-small-splashScreen">
          <Footer splashscreen={true}></Footer>
        </footer>
      </div>
    </div>
  );
};

export default SplashscreenLayout;
