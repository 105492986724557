import { Skeleton } from '@mui/material';
import React from 'react';


const Loading:React.FunctionComponent<{size?:number| undefined , sx?:object}> = ({
  size = 12,
  sx = {},
}) => {
  const spinnerSize = size + 'px';
  return (
    <Skeleton  animation='wave' variant="rounded" height={ spinnerSize } sx={ sx }/>
  );
} ;

export default Loading;
